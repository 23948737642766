<template>
  <div>
    <v-menu
      :open-on-hover="!touch"
      offset-x
      close-delay="100"
      content-class="tracking-number-action-menu"
      z-index="0"
    >
      <template #activator="{ on }">
        <v-icon v-on="on" class="mr-2 cursor-pointer">$wrenchIcon</v-icon>
      </template>
    
      <v-list class="leftDrawerHoverMenuBg">
        <template v-if="number['number_pool_id']">
          <v-list-item
            :ripple="false"
            @click="openDetachPoolDialog"
          >
            <v-list-item-title class="font-normal">Detach pool</v-list-item-title>
          </v-list-item>
          
          <v-list-item
            :ripple="false"
            @click="showJSCodForPool"
          >
            <v-list-item-title class="font-normal">Show JS code for pool</v-list-item-title>
          </v-list-item>
        </template>
      
        <v-list-item
          :ripple="false"
          @click="openAttachPoolDialog"
          v-else
        >
          <v-list-item-title class="font-normal">Attach pool</v-list-item-title>
        </v-list-item>
      
        <v-list-item
          :ripple="false"
          @click="openDetachTrafficSourceDialog"
          v-if="number['traffic_source_id']"
        >
          <v-list-item-title class="font-normal">Detach Traffic source</v-list-item-title>
        </v-list-item>
      
        <v-list-item
          :ripple="false"
          @click="openAttachTrafficSourceDialog"
          v-else
        >
          <v-list-item-title class="font-normal">Attach Traffic source</v-list-item-title>
        </v-list-item>
      
        <v-list-item
          :ripple="false"
          @click="openDetachNumberDialog"
        >
          <v-list-item-title class="font-normal">Detach number</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  
    <DialogAttachPoolToNumber
      v-model="attachPoolToNumberDialog"
      :pools="pools"
      :numberId="number.id"
      @attachSuccess="attachPoolToNumberSuccess"
    />
    
    <DialogShowJSCode
      v-model="showJSCodeDialog"
      :numberId="attachedNumberId"
    />
    
    <DialogAttachTrafficSourceToNumber
      v-model="attachTrafficSourceDialog"
      :trafficSources="trafficSources"
      :numberId="number.id"
      @attachSuccess="attachTrafficSourceToNumberSuccess"
    />
  
    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      :detachFromVendor="loadingDetachFromVendor"
      @actionSuccess="actionSuccess"
    />
  </div>
</template>

<script>
import DialogAttachPoolToNumber from '@/components/dialog/DialogAttachPoolToNumber'
import DialogShowJSCode from '@/components/dialog/DialogShowJSCode'
import DialogAttachTrafficSourceToNumber from '@/components/dialog/DialogAttachTrafficSourceToNumber'
import DialogConfirm from '@/components/dialog/DialogConfirm'

import { detachNumbersFromCampaign } from '@/api/campaign-routes'
import { detachPoolFromNumber } from '@/api/pool-routes'
import { detachTrafficSourceToNumber } from '@/api/traffic-source-routes'

export default {
  name: 'MenuTrackingNumberAction',
  inheritAttrs: false,
  components: { DialogAttachPoolToNumber, DialogShowJSCode, DialogAttachTrafficSourceToNumber, DialogConfirm },
  props: {
    number: {
      type: String | Number,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    vendorSlug: {
      type: String | Number,
      required: true
    },
    pools: {
      type: Array,
      required: true
    },
    trafficSources: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    attachPoolToNumberDialog: false,
    attachTrafficSourceDialog: false,
    showJSCodeDialog: false,
    confirmDialog: false,
    loadingAction: false,
    loadingDetachFromVendor: false,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    actionName: null,
    attachedNumberId: null,
  }),
  computed: {
    touch() {
      return this.$vuetify.breakpoint.width && matchMedia('(hover: none), (pointer: coarse)').matches
    },
    poolName() {
      const pool = this.pools.find(pool => pool.id === this.number['number_pool_id'])
      
      return pool ? pool.name : ''
    },
    trafficSourceName() {
      const trafficSource = this.trafficSources.find(trafficSource => trafficSource.id === this.number['traffic_source_id'])

      return trafficSource ? trafficSource.name : ''
    }
  },
  methods: {
    openAttachPoolDialog() {
      this.attachPoolToNumberDialog = true
    },
    openDetachPoolDialog() {
      this.actionName = 'goToDetachPool'
      this.dialogTitle = 'Detach Pool'
      this.dialogSubTitle = `${this.poolName}`
      this.dialogMessage = 'Are you sure you want to detach this Pool?'
      this.confirmDialog = true
    },
    async goToDetachPool() {
      this.loadingAction = true
      
      const { success, message } = await detachPoolFromNumber({
        'pool_id': this.number['number_pool_id'],
        'number_id': this.number.id
      })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('detachPoolFromNumberSuccess', {
          vendorSlug: this.vendorSlug,
          numberId: this.number.id,
        })
        this.confirmDialog = false
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAction = false
    },
    openDetachTrafficSourceDialog() {
      this.actionName = 'goToDetachTrafficSource'
      this.dialogTitle = 'Detach Traffic Source'
      this.dialogSubTitle = `${this.trafficSourceName}`
      this.dialogMessage = 'Are you sure you want to detach this Traffic Source?'
      this.confirmDialog = true
    },
    async goToDetachTrafficSource() {
      this.loadingAction = true
      
      const { success, message } = await detachTrafficSourceToNumber({
        'traffic_source_id': null,
        'number_id': this.number.id
      })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message ? message : 'Traffic Source was detached!' })
        this.$emit('detachTrafficSourceSuccess', {
          vendorSlug: this.vendorSlug,
          numberId: this.number.id,
        })
        this.confirmDialog = false
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAction = false
    },
    openAttachTrafficSourceDialog() {
      this.attachTrafficSourceDialog = true
    },
    attachTrafficSourceToNumberSuccess(trafficSourceId) {
      this.$emit('attachTrafficSourceToNumberSuccess', {
        vendorSlug: this.vendorSlug,
        numberId: this.number.id,
        trafficSourceId
      })
    },
    openDetachNumberDialog() {
      this.actionName = 'goToDetachNumber'
      this.dialogTitle = 'Detach Number'
      this.dialogSubTitle = `${this.number.number}`
      this.dialogMessage = 'Are you sure you want to detach this number from the campaign?'
      this.loadingDetachFromVendor = true
      this.confirmDialog = true
    },
    actionSuccess({ detachSuccessFormVendor = null }) {
      this[this.actionName](detachSuccessFormVendor)
    },
    async goToDetachNumber(detachSuccessFormVendor = null) {
      this.loadingAction = true
      
      const { success, message } = await detachNumbersFromCampaign({
        campaignId: this.campaignSlug,
        params: {
          numbers: [this.number.id],
          detachFromPublisher: detachSuccessFormVendor,
        }
      })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('detachNumberSuccess', {
          vendorSlug: this.vendorSlug,
          numberId: this.number.id,
        })
        this.confirmDialog = false
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAction = false
    },
    attachPoolToNumberSuccess(poolId) {
      this.$emit('attachPoolToNumberSuccess', {
        vendorSlug: this.vendorSlug,
        numberId: this.number.id,
        poolId
      })
      this.attachedNumberId = this.number.id
      this.showJSCodeDialog = true
    },
    showJSCodForPool() {
      this.attachedNumberId = this.number.id
      this.showJSCodeDialog = true
    }
  },
}
</script>

<style lang="scss">
  .tracking-number-action-menu {
    &.theme--light {
      box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
    }
    
    &.theme--dark {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28)!important;
    }
  }
</style>
