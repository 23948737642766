<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="520px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="5" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            Attach numbers
          </div>
        </v-col>
      </v-row>
      
      <v-form class="pb-20" ref="form" v-model="validForm">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-0" cols="12" sm="5">
            <span class="font-normal nameField--text text-sm-right">Select numbers</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="attachNumberName"
            ></portal-target>
          </v-col>
          
          <v-col cols="12" sm="7">
            <AutocompleteField
              v-model="formData.numbers"
              :items="numbers"
              item-text="number"
              item-value="id"
              :rules="rules"
              multiple
              return-object
            >
              <template #message="{ key, message }">
                <portal to="attachNumberName" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
              
              <template #selection="{ item, index }">
                <!--                <div class="pr-4" v-if="index < 2">-->
                <span class="nameField--text pr-4" v-if="index === 0">
                    {{ getCntNumbers }}
                  </span>
                <!--                  <span-->
                <!--                    v-if="index === 1"-->
                <!--                    class="nameField&#45;&#45;text font-xs-mobile"-->
                <!--                  >+{{ changedNumbers.length - 1 }} others</span>-->
                <!--                </div>-->
              </template>
            </AutocompleteField>
          </v-col>
        </v-row>
      </v-form>
      
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>
        
        <ActionButton class="ml-5 ml-sm-11" :loading="loading" @click="submitForm">Attach</ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import AutocompleteField from '@/components/select/AutocompleteField'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { attachNumbersToVendor } from '@/api/vendor-routes'
import { attachNumberToCampaign } from '@/api/campaign-routes'

export default {
  name: 'DialogAttachNumbersToCampaign',
  inheritAttrs: false,
  components: { AutocompleteField, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    numbers: {
      type: Array,
      required: true
    },
    vendorSlug: {
      required: true
    },
    campaignSlug: {
      required: true
    }
  },
  data: () => ({
    loading: false,
    validForm: true,
    formData: {
      numbers: [],
    },
    rules: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    getCntNumbers() {
      const cnt = this.formData.numbers.length

      return `${cnt} number${cnt - 1 ? 's' : ''}`
    },
  },
  methods: {
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        this.loading = true

        const freeNumbers = this.formData.numbers.filter(number => !number['publisher_id'])

        if (freeNumbers.length) {
          const { success, message } = await attachNumbersToVendor({
            vendorId: this.vendorSlug,
            formData: { numbers: freeNumbers.map(number => number.id) }
          })
          
          if (success) {
            this.$notify({ type: 'success-bg', text: message })
          } else {
            !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
          }
        }
        
        const { success, message } = await attachNumberToCampaign({
          campaignId: this.campaignSlug,
          formData: { numbers: this.formData.numbers.map(number => number.id) }
        })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.$emit('attachSuccess', this.formData.numbers)
          this.closeDialog()
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    formValidation() {
      this.rules = [
        v => !!v.length ||  'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show: {
      handler(value) {
        if (!value) {
          this.formData.numbers = []
          this.rules = []
        }
      }
    }
  }
}
</script>
