<template>
  <v-tooltip
    v-model="open"
    v-bind="$attrs"
    :max-width="maxWidth"
    :color="color"
    :open-delay="openDelay"
    :z-index="zIndex"
    class="rounded textTooltip"
  >
    <template #activator="{ on }">
      <v-hover #default="{ hover }">
        <v-icon
          v-on="on"
          class="font-small iconTooltip text-uppercase"
          :class="hover ? 'violet--text' : 'tooltipIcon--text'"
        >
          {{ letter }}
        </v-icon>
      </v-hover>
    </template>
    
    <span class="font-normal tooltipColor--text">{{ message }}</span>
  </v-tooltip>
</template>
<script>

export default {
  name: 'TooltipText',
  inheritAttrs: false,
  props: {
    message: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'tooltipBg'
    },
    maxWidth: {
      type: String | Number,
      default: 'auto'
    },
    openDelay: {
      type: [String, Number],
      default: 150
    },
    letter: {
      type: String,
      default: ''
    },
    zIndex: {
      type: [String, Number],
      default: 999
    }
  },
  data: () => ({
    open: false
  })
}
</script>
