<template>
  <div
    id="url-params"
    class="url-params-table-container"
    :class="{ 'panel-disabled disable-block': !campaignSlug }"
  >
    <v-row
      class="d-flex align-center pt-16 px-12"
      :class="checkSm ? 'pt-sp-23 px-sp-20' : 'pt-sm-25 px-sm-25'"
      no-gutters
    >
      <v-col cols="12" md="3" lg="2">
        <TextDescription :description="description.urlParams" />
      </v-col>
    
      <v-col cols="12" md="9" lg="10">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-wrap justify-space-between table-container pb-sm-6"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <SearchField
              v-model="search"
              class="mr-4 mr-sm-11 mb-4"
              :height="48"
            />

            <ActionOutlinedButton
              class="mb-4"
              @click="openUrlParamsPanel"
            >Add Url Params</ActionOutlinedButton>
          </v-col>
        
          <v-col
            class="table-container"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <AddedUrlParams
              :campaignUrlParameters.sync="urlParamsLocal"
              :campaignSlug="campaignSlug"
              :loadingDetailCampaign="loadingDetailCampaign"
              :search="search"
              @reloadCampaignManage="reloadCampaignManage"
              @openEditPanel="openEditPanel"
            />
          </v-col>
        
          <v-col
            class="divider-container pt-sm-25"
            :class="!visiblePanel ? 'pt-16' : 'pt-12'"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <v-divider v-if="!visiblePanel"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  
    <v-slide-y-transition>
      <div
        id="url-params-panel"
        class="tracking-pixel-container expandPanelBg panel-disabled"
        v-if="visiblePanel"
      >
        <UrlParamsPanel
          :description="description"
          :campaignSlug="campaignSlug"
          :freeUrlParams="freeUrlParamsLocal"
          :changedUrlParams="changedUrlParams"
          @createSuccess="createSuccess"
          @updateSuccess="updateSuccess"
          @attachSuccess="attachSuccess"
          @closePanel="closePanel"
        />
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import AddedUrlParams from '@/components/tables/AddedUrlParams'
import UrlParamsPanel from '@/components/campaign/panels/UrlParamsPanel'
import SearchField from '@/components/inputs/SearchField'

import { changeItemsAfterUpdate } from '@/helper/app-helper'

export default {
  name: 'CampaignUrlParams',
  inheritAttrs: false,
  components: { TextDescription, ActionOutlinedButton, AddedUrlParams, UrlParamsPanel, SearchField },
  props: {
    description: {
      type: Object,
      required: true
    },
    loadingDetailCampaign: {
      type: Boolean,
      required: true
    },
    campaignUrlParameters: {
      type: Array,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    freeUrlParams: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    visiblePanel: false,
    urlParamsLocal: [],
    freeUrlParamsLocal: [],
    changedUrlParams: null,
    search: null,
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    checkMd() {
      return this.$vuetify.breakpoint.width < 1600
    },
  },
  methods: {
    openUrlParamsPanel() {
      this.changedUrlParams = null
      this.visiblePanel = true
      this.scrollTo()
    },
    openEditPanel(urlParams) {
      this.changedUrlParams = urlParams
      this.visiblePanel = true
      this.scrollTo()
    },
    createSuccess(newUrlParams) {
      this.freeUrlParamsLocal.push(newUrlParams)
    },
    updateSuccess(changedUrlParams) {
      this.urlParamsLocal = changeItemsAfterUpdate(this.urlParamsLocal, changedUrlParams, 'id')
      this.closePanel()
    },
    attachSuccess(urlParams) {
      this.urlParamsLocal.push(urlParams)
      this.closePanel()
      this.reloadCampaignManage()
    },
    reloadCampaignManage() {
      this.$emit('reloadCampaignManage')
    },
    scrollTo(value = true) {
      if (value) {
        setTimeout(() => {
          this.$vuetify.goTo('#url-params-panel')
        })
      } else {
        this.$vuetify.goTo('#url-params')
      }
    },
    closePanel() {
      this.visiblePanel = false
      this.scrollTo(false)
    },
  },
  watch: {
    campaignUrlParameters: {
      handler(items) {
        this.urlParamsLocal = items
      }
    },
    freeUrlParams: {
      immediate: true,
      handler(items) {
        this.freeUrlParamsLocal = items
      }
    }
  },
}
</script>
