<template>
  <v-data-table
    :headers="headers"
    :items="items"
    item-key="slug"
    class="elevation-0 borderNone font-small tableBg borderTableXS"
    outlined
    disable-pagination
    hide-default-footer
    :custom-sort="customSort"
    :header-props="{ sortIcon: '$sortDownIcon' }"
    :loading="loadingDetailCampaign"
    :loader-height="2"
    :mobile-breakpoint="0"
  >
    <template #item.numbers="{ item }">
      <div class="py-6">
        <div
          class="d-flex flex-row align-center"
          :class="{ 'pb-4': index < item.numbers.length - 1 }"
          v-for="(number, index) of item.numbers"
          :key="number.id"
        >
          <MenuTrackingNumberAction
            :number="number"
            :campaignSlug="campaignSlug"
            :vendorSlug="item.slug"
            :pools="pools"
            :trafficSources="trafficSources"
            @detachNumberSuccess="detachNumberSuccess"
            @attachPoolToNumberSuccess="attachPoolToNumberSuccess"
            @detachPoolFromNumberSuccess="detachPoolFromNumberSuccess"
            @attachTrafficSourceToNumberSuccess="attachTrafficSourceToNumberSuccess"
            @detachTrafficSourceSuccess="detachTrafficSourceSuccess"
          />
          
          <div
            class="font-small d-flex justify-space-between vendorNumber"
            v-if="number.number"
          >
            <TooltipAction bottom :message="getStatusName(number['status_id'])" openDelay="300">
              <template #activator>
                <span
                  class="cursor-pointer"
                  :class="{ 'opacity-50': number['status_id'] === 2}"
                >
                  {{ number.number }}
                </span>
              </template>
            </TooltipAction>
  
            <div>
              <TooltipText
                bottom
                v-if="number['number_pool_id']"
                :letter="getNumberPoolId(pools, number)"
                :message="getNameById(pools, number['number_pool_id'])"
              />
    
              <TooltipText
                bottom
                v-if="number['traffic_source_id']"
                :letter="getTrafficSourceId(trafficSources, number)"
                :message="getNameById(trafficSources, number['traffic_source_id'])"
              />
            </div>
          </div>
          
          <v-spacer/>
        </div>
      </div>
    </template>
  
    <template #item.payout="{ item }">
      {{ currency(item.pivot.payout) }}
    </template>
  
    <template #item.status_id="{ item }">
      <SwitchSm
        :value="getStatus(item)"
        :disabled="!!item.private"
        :loading="loadingStatus"
        bgColor="switcherYellow"
        @input="changeStatus(item)"
      />
    </template>
    
    <template #item.actions="{ item }">
      <TooltipAction bottom message="Add">
        <template #activator>
          <v-icon class="mr-6" @click="openAttachNumberDialog(item)">$phoneIcon</v-icon>
        </template>
      </TooltipAction>
      
      <TooltipAction bottom message="Edit">
        <template #activator>
          <v-icon class="mr-6" @click="openTrackingNumberEditPanel(item)">$pencilIcon</v-icon>
        </template>
      </TooltipAction>
      
      <TooltipAction bottom message="Delete">
        <template #activator>
          <v-icon @click="openDetachVendorDialog(item)">$deleteIcon</v-icon>
        </template>
      </TooltipAction>
    </template>
    
    <template #no-data>
      <div class="d-flex">
        <span class="font-normal text-none text-left" v-if="!campaignVendors.length">No Vendors linked to this Campaign</span>
        <span class="font-normal text-left" v-else>No results found</span>
      </div>
    </template>
    
    <template #top>
      <DialogAttachNumbersToCampaign
        v-model="attachNumbersDialog"
        :numbers="freeNumbers"
        :vendorSlug="vendorSlugForAttach"
        :campaignSlug="campaignSlug"
        @attachSuccess="attachNumberSuccess"
      />
      
      <DialogConfirm
        v-model="confirmDialog"
        :loading="loadingAction"
        :title="dialogTitle"
        :subTitle="dialogSubTitle"
        :message="dialogMessage"
        @actionSuccess="actionSuccess"
      />
    </template>
  </v-data-table>
</template>

<script>
import TooltipText from '@/components/tooltip/TooltipText'
import TooltipAction from '@/components/tooltip/TooltipAction'
import SwitchSm from '@/components/controls/SwitchSm'
import MenuTrackingNumberAction from '@/components/menu/MenuTrackingNumberAction'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogAttachNumbersToCampaign from '@/components/dialog/DialogAttachNumbersToCampaign'
import currency from '@/mixins/currency'
import getNameById from '@/mixins/getNameById'

import { detachVendorFromCampaign, detachNumbersFromCampaign } from '@/api/campaign-routes'
import { getAllTrafficSources } from '@/api/traffic-source-routes'
import { updateVendor } from '@/api/vendor-routes'
import { getAllPools } from '@/api/pool-routes'
import { changeItemsAfterUpdate, customSorting } from '@/helper/app-helper'

export default {
  name: 'TrackingNumber',
  inheritAttrs: false,
  components: { TooltipText, TooltipAction, SwitchSm, MenuTrackingNumberAction, DialogConfirm, DialogAttachNumbersToCampaign },
  mixins: [ currency, getNameById ],
  props: {
    campaignSlug: {
      type: String | Number,
      required: true
    },
    campaignVendors: {
      type: Array,
      required: true
    },
    loadingDetailCampaign: {
      type: Boolean,
      required: true
    },
    freeNumbers: {
      type: Array,
      required: true
    },
    search: {
      type: String | Number,
      default: ''
    },
  },
  data: () => ({
    loadingAction: false,
    loadingStatus: false,
    pools: [],
    trafficSources: [],
    headers: [
      { text: 'VENDOR', value: 'name' },
      { text: 'NUMBER', value: 'numbers', sortable: false, class: 'pl-20 text-no-wrap', width: 210 },
      { text: 'PAYOUT', value: 'payout', align: 'center' },
      { text: 'STATUS', value: 'status_id', align: 'center' },
      { text: 'ACTIONS', value: 'actions', sortable: false, align: 'right', width: 130 },
    ],
    attachNumbersDialog: false,
    vendorSlugForAttach: null,
    actionName: null,
    confirmDialog: false,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
  }),
  computed: {
    items() {
      return this.searchItems(this.campaignVendors, this.search)
    },
  },
  methods: {
    searchItems(items, search) {
      if (!search) return items
      search = search.toString().toLowerCase()
      if (search.trim() === '') return items

      return items.filter(item => {
        if (item.name.toString().toLowerCase().indexOf(search) !== -1) return true

        let prev = search[0] === '+' ? '+' : ''

        if (!!item.numbers && item.numbers.length) {
          if (item.numbers.some(n => `${prev}${n.number.toString().toLowerCase()}`.indexOf(search) !== -1)) return true
        }

        prev = search[0] === '$' ? '$' : ''

        return `${prev}${item.pivot.payout.toString()}`.indexOf(search) !== -1
      })
    },
    async goToLoadAllPools() {
      const { success, payload, message } = await getAllPools()
      
      if (success) {
        this.pools = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async goToLoadTrafficSource() {
      const { success, payload, message } = await getAllTrafficSources()

      if (success) {
        this.trafficSources = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    getNumberPoolId(pools, number) {
      return this.getNameById(pools, number['number_pool_id']) ? 'N' : ''
    },
    getTrafficSourceId(trafficSources, number) {
      return this.getNameById(trafficSources, number['traffic_source_id']) ? 'T' : ''
    },
    getStatusName(statusId) {
      return statusId === 1 ? 'Enabled' : 'Paused'
    },
    getStatus(item) {
      return item['status_id'] === 1
    },
    openTrackingNumberEditPanel(item) {
      this.$emit('openTrackingNumberEditPanel', item)
    },
    openDetachVendorDialog(trackingNumber) {
      this.actionName = 'goToDetachVendorFromCampaign'
      this.selectedItem = trackingNumber
      this.dialogTitle = 'Detach vendor'
      this.dialogSubTitle = `${trackingNumber.name}`
      this.dialogMessage = 'Are you sure you want to detach this vendor?'
      this.confirmDialog = true
    },
    async goToDetachVendorFromCampaign() {
      this.loadingAction = true
      const { success, message } = await detachVendorFromCampaign({
        campaignId: this.campaignSlug,
        params: { publisher: this.selectedItem.slug }
      })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })

        this.$emit('update:campaignVendors', this.campaignVendors.filter(item => item.slug !== this.selectedItem.slug))
        if (!!this.selectedItem.numbers.length) {
          const numbers = this.selectedItem.numbers.map(number => number.id)
          this.goToDetachNumbersFromCampaign(numbers)
        } else {
          this.loadingAction = false
          this.$emit('reloadCampaignManage')
        }
      } else {
        this.loadingAction = false
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.confirmDialog = false
    },
    async goToDetachNumbersFromCampaign(numbers) {
      const { success, message } = await detachNumbersFromCampaign({
        campaignId: this.campaignSlug,
        params: { numbers }
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('reloadCampaignManage')
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAction = false
    },
    openAttachNumberDialog(vendor) {
      this.vendorSlugForAttach = vendor.slug
      this.attachNumbersDialog = true
    },
    attachNumberSuccess(numbers) {
      if (!!this.vendorSlugForAttach) {
        const index = this.getVendorIndex(this.vendorSlugForAttach)
        
        if (index + 1) {
          this.items[index].numbers.push(...numbers)

          this.updateItems()
        }
      }

      this.$emit('reloadCampaignManage')
    },
    changeStatus(vendor) {
      const formData = {
        name: vendor.name,
        'status_id': vendor['status_id'] === 1 ? 2 : 1,
        'create_numbers': vendor['create_numbers'],
        'see_record': vendor['see_record'],
      }
      this.updateVendor({
        vendorId: vendor.slug,
        formData,
      })
    },
    async updateVendor({ vendorId, formData }) {
      this.loadingStatus = true

      const { success, payload, message } = await updateVendor({ vendorId, formData })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })

        this.$emit('update:campaignVendors', changeItemsAfterUpdate(this.campaignVendors, payload))
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingStatus = false
    },
    actionSuccess() {
      this[this.actionName]()
    },
    detachNumberSuccess({ vendorSlug, numberId }) {
      const index = this.getVendorIndex(vendorSlug)

      if (index + 1) {
        this.items[index].numbers = this.items[index].numbers.filter(number => number.id !== numberId)

        this.updateItems()
      }
      
      this.$emit('reloadCampaignManage')
    },
    attachPoolToNumberSuccess({ vendorSlug, numberId, poolId }) {
      const index = this.getVendorIndex(vendorSlug)

      if (index + 1) {
        this.items[index].numbers = this.items[index].numbers.map(number => {
          if (number.id === numberId) {
            number['number_pool_id'] = poolId
          }
          return number
        })

        this.updateItems()
      }
    },
    detachPoolFromNumberSuccess({ vendorSlug, numberId }) {
      const index = this.getVendorIndex(vendorSlug)

      if (index + 1) {
        this.items[index].numbers = this.items[index].numbers.map(number => {
          if (number.id === numberId) {
            number['number_pool_id'] = null
          }
          return number
        })

        this.updateItems()
      }
    },
    attachTrafficSourceToNumberSuccess({ vendorSlug, numberId, trafficSourceId }) {
      const index = this.getVendorIndex(vendorSlug)

      if (index + 1) {
        this.items[index].numbers = this.items[index].numbers.map(number => {
          if (number.id === numberId) {
            number['traffic_source_id'] = trafficSourceId
          }
          return number
        })

        this.updateItems()
      }
    },
    detachTrafficSourceSuccess({ vendorSlug, numberId }) {
      const index = this.getVendorIndex(vendorSlug)

      if (index + 1) {
        this.items[index].numbers = this.items[index].numbers.map(number => {
          if (number.id === numberId) {
            number['traffic_source_id'] = null
          }
          return number
        })

        this.updateItems()
      }
    },
    getVendorIndex(vendorSlug) {
      return this.items.findIndex(item => item.slug === vendorSlug)
    },
    updateItems() {
      this.$emit('update:campaignVendors', this.campaignVendors)
    },
    customSort(items, index, isDesc) {
      const fieldName = index[0]
      const type = this.getFieldType(fieldName)

      items.sort((a, b) => {
        const f = fieldName === 'payout' ? +a['pivot']['payout'] : a[fieldName]
        const s = fieldName === 'payout' ? +b['pivot']['payout'] : b[fieldName]
        
        if(typeof f !== 'undefined') {
          return customSorting(f, s, !!isDesc[0], type)
        }
      })
      return items
    },
    getFieldType(name) {
      if (name === 'payout') {
        return 'number'
      }

      if (name === 'status_id') {
        return 'status'
      }

      return 'string'
    },
  },
  watch: {
    loadingStatus: {
      handler(value) {
        if (!value) {
          this.$emit('changeStatusSuccess')
        }
      }
    },
    campaignSlug: {
      handler(slug) {
        if (!!slug) {
          this.goToLoadAllPools()
          this.goToLoadTrafficSource()
        }
      }
    },
  },
}
</script>

<style lang="scss">
.cursor-pointer:hover {
  cursor: pointer !important;
}

.vendorNumber {
  width: 100%;
}
</style>
