<template>
  <div
    id="tracking-number-dialog-new"
    class="tracking-number-container"
    :class="{ 'panel-disabled disable-block': !campaignSlug }"
  >
    <v-row
      class="d-flex align-center pt-16 px-12"
      :class="checkSm ? 'pt-sp-23 px-sp-20' : 'pt-sm-25 px-sm-25'"
      no-gutters
    >
      <v-col cols="12" md="3" lg="2">
        <TextDescription :description="description.trackingNumber" />
      </v-col>

      <v-col cols="12" md="9" lg="10">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-wrap justify-space-between table-container pb-sm-6"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <div class="d-flex">
              <SearchField
                v-model="search"
                class="mr-4 mr-sm-11 mb-4"
                :height="48"
              />

              <TooltipAction bottom message="Detach selected numbers">
                <template #activator>
                  <IconButton
                    class="mr-5 mr-md-11"
                    :disabled="loadingDetailCampaign || !selectedNumbers.length"
                    icon="deleteBothIcon"
                    @click="openDialogDetachNumbers"
                  />
                </template>
              </TooltipAction>
            </div>

            <ActionOutlinedButton class="mb-4" @click="openAddNumberPanel">Add Number</ActionOutlinedButton>
          </v-col>

          <v-col
            class="table-container"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <TrackingNumbers
              :campaignSlug="campaignSlug"
              :campaignNumbers.sync="campaignNumbersLocal"
              :campaignVendors="campaignVendorsLocal"
              :selectedNumbers.sync="selectedNumbers"
              :loadingDetailCampaign="loadingDetailCampaign"
              :search="search"
              @reloadCampaignManage="reloadCampaignManage"
              @openEditNumber="openEditNumber"
            />
          </v-col>

          <v-col
            class="divider-container pt-sm-25"
            :class="!visibleDialog ? 'pt-16' : 'pt-12'"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <v-divider v-if="!visibleDialog"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <DialogCampaignNumberTabs
      v-model="visibleDialog"
      :description="description"
      :campaignSlug="campaignSlug"
      :editNumber="editNumber"
      :campaignVendors="campaignVendorsLocal"
      :freeNumbers="freeNumbers"
      :freeVendors="freeVendors"
      :currentCountryId="currentCountryId"
      @attachSuccess="attachNumbersToCampaignSuccess"
      @updateNumberSuccess="updateNumberSuccess"
      @poolActionSuccess="poolActionSuccess"
      @updateVendorSuccess="updateVendorSuccess"
      @reloadCampaignManage="reloadCampaignManage"
    />

    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      title="Detach numbers"
      message="Are you sure you want to detach selected numbers?"
      @actionSuccess="detachSelectedNumbers"
    />
  </div>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import IconButton from '@/components/buttons/IconButton'
import TrackingNumbers from '@/components/tables/TrackingNumbers'
import CampaignNumberTabs from '@/components/tabs/CampaignNumberTabs'
import CardBuyNumbers from '@/components/cards/CardBuyNumbers'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogCampaignNumberTabs from '@/components/dialog/campaign/DialogCampaignNumberTabs'
import SearchField from '@/components/inputs/SearchField'
import TooltipAction from '@/components/tooltip/TooltipAction'

import { changeItemsAfterUpdate, cloneArray } from '@/helper/app-helper'
import { detachNumbersFromCampaign } from '@/api/campaign-routes'

export default {
  name: 'CampaignTrackingNumberDialog',
  inheritAttrs: false,
  components: { TextDescription, ActionOutlinedButton, IconButton, TrackingNumbers, CampaignNumberTabs, CardBuyNumbers, DialogConfirm, DialogCampaignNumberTabs, SearchField, TooltipAction },
  props: {
    description: {
      type: Object,
      required: true
    },
    campaignNumbers: {
      type: Array,
      default: () => []
    },
    campaignVendors: {
      type: Array,
      default: () => []
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    currentCountryId: {
      type: String | Number,
      default: null
    },
    loadingDetailCampaign: {
      type: Boolean,
      required: true
    },
    freeVendors: {
      type: Array,
      required: true
    },
    freeNumbers: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    confirmDialog: false,
    loadingAction: false,
    visibleDialog: false,
    campaignNumbersLocal: [],
    campaignVendorsLocal: [],
    selectedNumbers: [],
    search: null,
    editNumber: null,
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    checkMd() {
      return this.$vuetify.breakpoint.width < 1600
    },
  },
  methods: {
    openEditNumber(number) {
      this.editNumber = number
      this.visibleDialog = true
    },
    openAddNumberPanel() {
      this.editNumber = null
      this.visibleDialog = true
    },
    updateNumberSuccess({ trafficSource, number }) {
      const currentNumber = this.campaignNumbersLocal.find(n => n.id === number.id)

      if (currentNumber) {
        currentNumber.name = number.name
        currentNumber.publisher = number.publisher
        currentNumber.pivot = !!number.publisher ? currentNumber.pivot : null
        currentNumber['publisher_id'] = !!number.publisher ? number.publisher.id : null
        currentNumber['traffic_source'] = trafficSource
        currentNumber['traffic_source_id'] = !!trafficSource ? trafficSource.id : null

        this.campaignNumbersLocal = changeItemsAfterUpdate(this.campaignNumbersLocal, currentNumber, 'id')
      }
    },
    poolActionSuccess({ pool, numberId}) {
      const currentNumber = this.campaignNumbersLocal.find(n => n.id === numberId)

      if (currentNumber) {
        currentNumber['number_pool'] = pool
        currentNumber['number_pool_id'] = !!pool ? pool.id : null

        this.campaignNumbersLocal = changeItemsAfterUpdate(this.campaignNumbersLocal, currentNumber, 'id')
      }
    },
    updateVendorSuccess(vendor) {
      this.updateCampaignVendor(vendor)

      const updatedNumbers = this.campaignNumbersLocal.map(n => ({
        ...n,
        pivot: n['publisher_id'] === vendor.id ? vendor.pivot : n.pivot
      }))

      this.campaignNumbersLocal = cloneArray(updatedNumbers)
    },
    updateCampaignVendor(vendor) {
      const index = this.campaignVendorsLocal.findIndex(v => v.slug === vendor.slug)

      if (index + 1) {
        this.campaignVendorsLocal = changeItemsAfterUpdate(this.campaignVendorsLocal, vendor)
      } else {
        this.campaignVendorsLocal.push(vendor)
      }
    },
    reloadCampaignManage() {
      this.$emit('reloadCampaignManage')
    },
    closeDialog() {
      this.visibleDialog = false
      this.editNumber = null
    },
    attachNumbersToCampaignSuccess(numbers) {
      this.campaignNumbersLocal.push(...numbers)
      this.reloadCampaignManage()
      this.closeDialog()
    },
    openDialogDetachNumbers() {
      this.confirmDialog = true
    },
    async detachSelectedNumbers() {
      this.loadingAction = true

      const { success, message } = await detachNumbersFromCampaign({
        campaignId: this.campaignSlug,
        params: { numbers: this.selectedNumbers.map(n => n.id) }
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.campaignNumbersLocal = this.campaignNumbersLocal.filter(n => this.selectedNumbers.every(sn => sn.id !== n.id))
        this.selectedNumbers = []
        this.reloadCampaignManage()
        this.confirmDialog = false
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
  },
  watch: {
    campaignNumbers: {
      handler(numbers) {
        this.campaignNumbersLocal = cloneArray(numbers)
      }
    },
    campaignVendors: {
      handler(vendors) {
        this.campaignVendorsLocal = cloneArray(vendors)
      }
    },
  },
}
</script>