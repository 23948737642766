<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block d-flex align-center justify-space-between pb-8 pb-sm-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">campaign</div>
        </portal>
        
        <ActionButton @click="closeAction">Manage campaigns</ActionButton>
      </div>
      
      <div>
        <CancelButton @click="closeAction">Close</CancelButton>
      </div>
    </div>
  
    <v-card class="create-card no-padding cardBg" flat outlined>
      <div class="setup-container">
        <CampaignSetup
          :description="description.setup"
          :campaignSetupData="campaignSetupData"
          :campaignSlug="campaignSlug"
          :loadingDetailCampaign="loading"
        />
      </div>
  
      <CampaignTrackingNumber
        :description="description.vendorPanel"
        :campaignNumbers="campaignNumbers"
        :campaignVendors="campaignVendors"
        :campaignSlug="campaignSlug"
        :loadingDetailCampaign="loading"
        :currentCountryId="currentCountryId"
        :freeVendors="freeVendors"
        :freeNumbers="freeNumbers"
        @reloadCampaignManage="goToLoadCampaignManage"
      />

<!--      <CampaignTrackingNumberDialog-->
<!--        :description="description.vendorPanel"-->
<!--        :campaignNumbers="campaignNumbers"-->
<!--        :campaignVendors="campaignVendors"-->
<!--        :campaignSlug="campaignSlug"-->
<!--        :loadingDetailCampaign="loading"-->
<!--        :currentCountryId="currentCountryId"-->
<!--        :freeVendors="freeVendors"-->
<!--        :freeNumbers="freeNumbers"-->
<!--        @reloadCampaignManage="goToLoadCampaignManage"-->
<!--      />-->

<!--      <CampaignTrackingVendorNumber-->
<!--        :description="description.vendorPanel"-->
<!--        :campaignVendors="campaignVendors"-->
<!--        :campaignSlug="campaignSlug"-->
<!--        :loadingDetailCampaign="loading"-->
<!--        :currentCountryId="currentCountryId"-->
<!--        :freeVendors="freeVendors"-->
<!--        :freeNumbers="freeNumbers"-->
<!--        @reloadCampaignManage="goToLoadCampaignManage"-->
<!--      />-->
  
      <CampaignCallRouting
        :description="description.destinationPanel"
        :campaignDestinationsAndGroups="campaignDestinationsAndGroups"
        :campaignSlug="campaignSlug"
        :campaignIvrId="campaignIvrId"
        :loadingDetailCampaign="loading"
        :freeDestinations="freeDestinations"
        :freeGroups="freeGroups"
        @reloadCampaignManage="goToLoadCampaignManage"
      />
      
<!--      <CampaignUrlParams-->
<!--        :description="description.urlParamsPanel"-->
<!--        :campaignUrlParameters="campaignUrlParams"-->
<!--        :campaignSlug="campaignSlug"-->
<!--        :loadingDetailCampaign="loading"-->
<!--        :freeUrlParams="freeUrlParams"-->
<!--        @reloadCampaignManage="goToLoadCampaignManage"-->
<!--      />-->
<!--      -->
<!--      <CampaignTrackingPixel-->
<!--        :description="description.pixelPanel"-->
<!--        :campaignPixels="campaignPixels"-->
<!--        :campaignSlug="campaignSlug"-->
<!--        :loadingDetailCampaign="loading"-->
<!--        :freePixels="freePixels"-->
<!--        @reloadCampaignManage="goToLoadCampaignManage"-->
<!--      />-->
    </v-card>
  </v-container>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import CampaignSetup from '@/components/campaign/CampaignSetup'
import CampaignTrackingNumber from '@/components/campaign/CampaignTrackingNumber'
import CampaignTrackingNumberDialog from '@/components/campaign/CampaignTrackingNumberDialog'
import CampaignTrackingVendorNumber from '@/components/campaign/CampaignTrackingVendorNumber'
import CampaignCallRouting from '@/components/campaign/CampaignCallRouting'
import CampaignUrlParams from '@/components/campaign/CampaignUrlParams'
import CampaignTrackingPixel from '@/components/campaign/CampaignTrackingPixel'

import { getDetailCampaign, getCampaignManage } from '@/api/campaign-routes'
import { getNameById } from '@/helper/app-helper'

import { mapGetters } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'DetailCampaign',
  components: { ActionButton, CancelButton, CampaignSetup, CampaignTrackingNumber, CampaignTrackingNumberDialog, CampaignTrackingVendorNumber, CampaignCallRouting, CampaignUrlParams, CampaignTrackingPixel },
  data: () => ({
    loading: false,
    campaignSlug: null,
    noScrolling: false,
    detailCampaign: null,
    description: {
      setup: {
        title: 'Setup Your Campaign',
        text: [
          'Name your Campaign and',
          'choose your country.'
        ]
      },
      vendorPanel: {
        trackingNumber: {
          title: 'Get a Tracking Number',
          text: [
            'Get a unique Tracking Number',
            'where you or your partners',
            'can send calls.',
          ]
        },
        addVendor: {
          title: 'ADD VENDOR',
          text: []
        },
        addNumber: {
          title: 'ADD NUMBERS',
          text: []
        },
        editNumber: {
          title: 'EDIT TRACKING NUMBER',
          text: []
        },
        editVendor: {
          title: 'EDIT TRACKING NUMBER',
          text: []
        },
        byNumbers: {
          title: 'BUY NUMBERS',
          text: []
        },
      },
      destinationPanel: {
        destination: {
          title: 'Add a Destination',
          text: [
            'Forward your calls to buyers, call ',
            'centers, or anywhere you\'d like them',
            'to go.',
          ]
        },
        addDestination: {
          title: 'ADD DESTINATION',
          text: []
        },
        editCallRoute: {
          title: 'EDIT CALL ROUTE',
          text: []
        },
        createDestination: {
          title: 'CREATE DESTINATION',
          text: []
        },
        addGroup: {
          title: 'ADD GROUP',
          text: []
        },
        createGroup: {
          title: 'CREATE GROUP',
          text: []
        },
        ivr: {
          title: 'Add IVR',
          text: [
            'Forward your calls to buyers, call ',
            'centers, or anywhere you\'d like them',
            'to go.',
          ]
        },
      },
      urlParamsPanel: {
        urlParams: {
          title: 'Get a Url Params',
          text: []
        },
        addUrlParams: {
          title: 'ADD URL PARAMS',
          text: []
        },
        editUrlParams: {
          title: 'EDIT URL PARAMS',
          text: []
        },
        createUrlParams: {
          title: 'CREATE URL PARAMETER',
          text: []
        },
      },
      pixelPanel: {
        pixel: {
          title: 'Get a Tracking Pixel',
          text: []
        },
        addPixel: {
          title: 'ADD TRACKING PIXEL',
          text: []
        },
        editPixel: {
          title: 'EDIT TRACKING PIXEL',
          text: []
        },
        createPixel: {
          title: 'CREATE TRACKING PIXEL',
          text: []
        },
      },
    },
    freeVendors: [],
    freeNumbers: [],
    freeDestinations: [],
    freeGroups: [],
    freeUrlParams: [],
    freePixels: [],
  }),
  computed: {
    ...mapGetters({
      getCurrentAccount: authTypes.getters.GET_CURRENT_ACCOUNT,
    }),
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    campaignSetupData() {
      return !!this.detailCampaign ? {
        name: this.detailCampaign.name,
        'country_id': this.detailCampaign['country_id'],
        'status_id': this.detailCampaign['status_id'],
        'timezone_id':this.detailCampaign['timezone_id'],
        'hours_of_operation': this.detailCampaign['hours_of_operation'],
        'prev_connected_call_route_id': this.detailCampaign['prev_connected_call_route_id'],
        'target_dial_attempts': this.detailCampaign['target_dial_attempts'],
        'dupes_strict': !!this.detailCampaign['dupes_strict'],
        'anonymous_as_duplicate': !!this.detailCampaign['anonymous_as_duplicate'],
        'payout_once_per_caller': !!this.detailCampaign['payout_once_per_caller'],
        'record_calls': !!this.detailCampaign['record_calls'],
        'filter_anonymous': !!this.detailCampaign['filter_anonymous'],
        'spam_time': this.detailCampaign['spam_time'],
        'tone_music_url': this.detailCampaign['tone_music_url'],
        'weights_sorting': !!this.detailCampaign['weights_sorting'],
      } : {}
    },
    campaignDestinationsAndGroups() {
      const campaignDestinations = !!this.detailCampaign ? this.detailCampaign.targets.filter(destination => !destination.hide) : []
      const campaignGroups = !!this.detailCampaign ? this.detailCampaign['target_groups'].map(group => ({
        ...group,
        isGroup: true
      })) : []
      
      return campaignDestinations.concat(campaignGroups)
    },
    campaignIvrId() {
      return !!this.detailCampaign ? this.detailCampaign['ivr_id'] : null
    },
    campaignUrlParams() {
      return !!this.detailCampaign ? this.detailCampaign['url_params'] : []
    },
    campaignPixels() {
      return !!this.detailCampaign ? this.detailCampaign.pixels : []
    },
    currentCountryId() {
      return !!this.detailCampaign ? this.detailCampaign['country_id'] : null
    },
    campaignNumbers() {
      return !!this.detailCampaign ? this.detailCampaign.numbers : []
    },
    campaignVendors() {
      return !!this.detailCampaign ? this.detailCampaign.publishers.map(vendor => {
        vendor.numbers = getNameById(this.campaignNumbers, vendor.id, 'name', 'filter', 'publisher_id')

        return vendor
      }) : []
    },
  },
  mounted() {
    if (this.$route.params.slug) {
      this.noScrolling = !this.$route.query?.afterCreate
      this.campaignSlug = this.$route.params.slug
      this.goToLoadDetailCampaign()
      this.goToLoadCampaignManage()
    }
  },
  methods: {
    closeAction() {
      this.$router.push({ name: 'ManageCampaigns' })
    },
    async goToLoadDetailCampaign() {
      this.loading = true
      
      const { success, payload, message } = await getDetailCampaign(this.campaignSlug)

      if (success) {
        this.detailCampaign = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    async goToLoadCampaignManage() {
      const { success, payload, message } = await getCampaignManage(this.campaignSlug)

      if (success) {
        this.parseCampaignManageData(payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    parseCampaignManageData(manageData) {
      this.freeVendors = manageData.vendors
      this.freeNumbers = manageData.numbers
      this.freeGroups = manageData.targetGroups
      this.freeUrlParams = manageData.urlParams
      this.freePixels = manageData.pixels
      this.freeDestinations = manageData.destinations.map(destination => {
        destination.nameAndNumber = `${destination.name} - ${destination.destination}`
        return destination
      })
    },
  },
  watch: {
    campaignSlug: {
      handler(value) {
        if (value && !this.noScrolling) {
          setTimeout(() => {
            this.$vuetify.goTo('#tracking-number-new')
          })
        }
        this.noScrolling = false
      }
    },
  },
}
</script>
