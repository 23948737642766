<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="500px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="5" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            Attach Pool
          </div>
        </v-col>
      </v-row>
      
      <v-form class="pb-20" ref="form" v-model="validForm">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-0" cols="12" sm="5">
            <span class="font-normal nameField--text text-sm-right">Select pool</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="attachPoolName"
            ></portal-target>
          </v-col>
          
          <v-col cols="12" sm="7">
            <AutocompleteField
              v-model="formData['pool_id']"
              :items="pools"
              item-text="name"
              item-value="id"
              :rules="rules"
            >
              <template #message="{ key, message }">
                <portal to="attachPoolName" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </AutocompleteField>
          </v-col>
        </v-row>
      </v-form>
      
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>
        
        <ActionButton class="ml-5 ml-sm-11" :loading="loading" @click="submitForm">Attach</ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import AutocompleteField from '@/components/select/AutocompleteField'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { attachPoolToNumber } from '@/api/pool-routes'

export default {
  name: 'DialogAttachPoolToNumber',
  inheritAttrs: false,
  components: { AutocompleteField, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    pools: {
      type: Array,
      required: true
    },
    numberId: {
      type: String | Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    validForm: true,
    formData: {
      'number_id': null,
      'pool_id': null
    },
    rules: []
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        this.formData['number_id'] = this.numberId
        this.loading = true
        
        const { success, message } = await attachPoolToNumber(this.formData)
        
        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.$emit('attachSuccess', this.formData['pool_id'])
          this.closeDialog()
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
        
        this.loading = false
      }
    },
    formValidation() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show(value) {
      if (!value) {
        this.rules = []
        this.$refs.form.reset()
      }
    }
  }
}
</script>
