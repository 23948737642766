import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getUrlParams = params => axios.get(`${getAccountId()}/url-params`, { params })
const getAllUrlParams = () => axios.get(`${getAccountId()}/url-params-all`)
const getAllTreeUrlParams = () => axios.get(`${getAccountId()}/url-params-all-tree`)
const getDetailUrlParams = urlParamsId => axios.get(`${getAccountId()}/url-params/${urlParamsId}`)
const createUrlParams = formData => axios.post(`${getAccountId()}/url-params`, formData)
const updateUrlParams = ({ urlParamsId, formData }) => axios.patch(`${getAccountId()}/url-params/${urlParamsId}`, formData)
const deleteUrlParams = urlParamsId => axios.delete(`${getAccountId()}/url-params/${urlParamsId}`)

const getPixels = params => axios.get(`${getAccountId()}/pixels`, { params })
const getAllPixels = () => axios.get(`${getAccountId()}/pixels-all`)
const getDetailPixel = pixelId => axios.get(`${getAccountId()}/pixels/${pixelId}`)
const getPixelEvents = () => axios.get('pixels/settings/events')
const createPixel = formData => axios.post(`${getAccountId()}/pixels`, formData)
const updatePixel = ({ pixelId, formData }) => axios.patch(`${getAccountId()}/pixels/${pixelId}`, formData)
const deletePixel = pixelId => axios.delete(`${getAccountId()}/pixels/${pixelId}`)

export {
  getUrlParams, getAllUrlParams, getAllTreeUrlParams, getDetailUrlParams, createUrlParams, updateUrlParams, deleteUrlParams,
  getPixels, getAllPixels, getDetailPixel, getPixelEvents, createPixel, updatePixel, deletePixel
}
