<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="560px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="5" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            Create Vendor
          </div>
        </v-col>
      </v-row>
      <v-form class="pb-20" ref="form" v-model="validForm">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10" cols="12" sm="5">
            <span class="font-normal nameField--text text-sm-right">Vendor name</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="vendorName"
            ></portal-target>
          </v-col>
  
          <v-col class="pb-8 pb-sm-10" cols="12" sm="7">
            <FormField
              v-model="formData.name"
              :rules="rules"
            >
              <template #message="{ key, message }">
                <portal to="vendorName" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </FormField>
          </v-col>
  
          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-10" cols="12" sm="5">
            <span class="font-normal nameField--text">Number creation</span>
          </v-col>
  
          <v-col class="pt-sm-1 pb-8 pb-sm-0" cols="12" sm="7">
            <SwitchLg
              v-model="formData['create_numbers']"
              bgColor="switcherViolet"
            />
          </v-col>
  
          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-0" cols="12" sm="5">
            <span class="font-normal nameField--text">See record</span>
          </v-col>
  
          <v-col class="pt-sm-1" cols="12" sm="7">
            <SwitchLg
              v-model="formData['see_record']"
              bgColor="switcherViolet"
            />
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>
        
        <ActionButton class="ml-5 ml-sm-11" :loading="loading" @click="submitForm">Create</ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import SwitchLg from '@/components/controls/SwitchLg'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { createVendor } from '@/api/vendor-routes'

export default {
  name: 'DialogVendor',
  inheritAttrs: false,
  components: { FormField, SwitchLg, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    loading: false,
    validForm: true,
    formData: {
      name: null,
      'create_numbers': null,
      'see_record': null
    },
    rules: []
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        this.loading = true
        
        const formData = {
          ...this.formData,
          'status_id': 1
        }
        
        const { success, payload, message } = await createVendor(formData)
        
        if (success) {
          this.$emit('createSuccess', payload.slug)
          this.closeDialog()
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
        
        this.loading = false
      }
    },
    formValidation() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show(value) {
      if (!value) {
        this.rules = []
        this.$refs.form.reset()
        this.load = false
      }
    }
  }
}
</script>

<style lang="scss">
.switcher > .switcherViolet {
  height: 20px;
}
</style>
