<template>
  <v-row
    class="d-flex align-center pa-12 py-md-20"
    :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
    no-gutters
  >
    <v-col cols="12" md="3" lg="2">
      <TextDescription :description="description" />
    </v-col>
    <v-col cols="12" md="9" lg="10">
      <v-form ref="attachNumber" v-model="validateForm">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Select numbers</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="attachNumbers"
            ></portal-target>
          </v-col>

          <v-col class="d-flex flex-wrap" cols="12" sm="8" md="9">
            <AutocompleteField
              class="field-container mr-sp-11 pb-5"
              v-model="changedNumbers"
              :items="freeNumbersAll"
              item-text="number"
              item-value="id"
              multiple
              return-object
              checkBg
              :rules="rulesArray"
            >
              <template #message="{ key, message }">
                <portal to="attachNumbers" v-if="!!message">
                  {{ message }}
                </portal>
              </template>

              <template #selection="{ item, index }">
                <span class="nameField--text pr-4" v-if="index === 0">
                    {{ getCntNumbers }}
                </span>
              </template>
            </AutocompleteField>

            <div class="pb-10">
              <ActionOutlinedButton
                @click="buyNumberTab"
                height="46"
              >
                Buy New Number
              </ActionOutlinedButton>
            </div>
          </v-col>

          <v-col class="pt-5 pt-md-10" cols="12" sm="8" md="9" offset-sm="4" offset-md="3">
            <CancelButton
              class="mr-5 mr-sm-10"
              @click="closePanel"
            >
              Cancel
            </CancelButton>

            <ActionButton
              :loading="loading"
              @click="submitForm"
            >
              Add Number{{ this.changedNumbers.length > 1 ? 's' : '' }}
            </ActionButton>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import AutocompleteField from '@/components/select/AutocompleteField'

import { attachNumberToCampaign } from '@/api/campaign-routes'

export default {
  name: 'AttachNumberPanel',
  inheritAttrs: false,
  components: { TextDescription, ActionOutlinedButton, CancelButton, ActionButton, AutocompleteField },
  props: {
    description: {
      type: Object,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    freeNumbersAll: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    validateForm: true,
    loading: false,
    rulesArray: [],
    changedNumbers: [],
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    getCntNumbers() {
      const cnt = this.changedNumbers.length

      return `${cnt} number${cnt - 1 ? 's' : ''}`
    },
  },
  methods: {
    buyNumberTab() {
      this.$emit('buyNumbersTab')
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.attachNumber.validate()) {
        this.loading = true

        const { success, payload, message } = await attachNumberToCampaign({
          campaignId: this.campaignSlug,
          formData: { numbers: this.changedNumbers.map(n => n.id) }
        })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.$emit('attachSuccess', this.changedNumbers)
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    validationForm() {
      this.rulesArray = [
        v => !!v && !!v.length || 'This field is required'
      ]
    },
    closePanel() {
      this.$emit('closePanel')
    },
  },
}
</script>