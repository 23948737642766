import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getIvrs = params => axios.get(`${getAccountId()}/ivr/paginated`, { params })
const getAllIvrs = () => axios.get(`${getAccountId()}/ivr/all`)
const getDetailIvr = ivrId => axios.get(`${getAccountId()}/ivr/${ivrId}`)
const createIvr = formData => axios.post(`${getAccountId()}/ivr`, formData)
const editIvr = ({ ivrId, formData }) => axios.patch(`${getAccountId()}/ivr/${ivrId}`, formData)
const deleteIvr = ivrId => axios.delete(`${getAccountId()}/ivr/${ivrId}`)

const getLanguages = () => axios.get(`${getAccountId()}/platform/languages`)

export {
  getIvrs, getAllIvrs, getDetailIvr, createIvr, editIvr, deleteIvr, getLanguages
}