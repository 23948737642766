<template>
  <v-form ref="form" v-model="validateForm">
    <v-row no-gutters>
      <v-col
        class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
        cols="12" sm="4" md="3"
      >
        <span class="font-normal nameField--text text-sm-right">IVR Tree</span>
        <portal-target
          class="field-message font-normal error--text text-sm-right"
          name="ivrTree"
        ></portal-target>
      </v-col>
      <v-col class="pb-10" cols="12" sm="8" md="9">
        <AutocompleteField
          class="field-container"
          v-model="currentIvrSlug"
          :items="ivrs"
          item-text="name"
          item-value="slug"
          :rules="rules"
          :loading="loading"
          checkBg
        >
          <template #message="{ key, message }">
            <portal to="ivrTree" v-if="!!message">
              {{ message }}
            </portal>
          </template>
        </AutocompleteField>
      </v-col>
    
      <v-col
        class="pt-2 pt-sm-10"
        cols="12" sm="8" md="9" offset-sm="4" offset-md="3"
      >
        <ActionButton @click="submitForm" :loading="loadingAction">Save</ActionButton>
      </v-col>
    
      <v-col
        class="divider-container pt-sm-25 pt-16"
        :style="checkMd ? 'max-width: 1037px;' : ''"
        cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
      >
        <v-divider />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import AutocompleteField from '@/components/select/AutocompleteField'
import ActionButton from '@/components/buttons/ActionButton'

import { getAllIvrs } from '@/api/ivr-routes'
import { attachIvrToCampaign } from '@/api/campaign-routes'

export default {
  name: 'AttachIvrPanel',
  inheritAttrs: false,
  components: { AutocompleteField, ActionButton },
  props: {
    campaignSlug: {
      type: String | Number,
      required: true
    },
    campaignIvrId: {
      type: String | Number,
      required: true
    },
  },
  data: () => ({
    validateForm: true,
    loading: false,
    loadingAction: false,
    currentIvrSlug: null,
    ivrs: [],
    rules: [],
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width < 1600
    },
  },
  mounted() {
    this.loadAllIvrs()
  },
  methods: {
    async loadAllIvrs() {
      this.loading = true
      
      const { success, payload, message } = await getAllIvrs()

      if (success) {
        this.ivrs = payload
        
        this.checkAttachedIvr()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loadingAction = true

        const { success, payload, message } = await attachIvrToCampaign({
          campaignId: this.campaignSlug,
          ivrId: this.currentIvrSlug
        })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.$emit('update:campaignIvrId', payload.id)
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loadingAction = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
    },
    checkAttachedIvr() {
      if (!this.campaignIvrId) {
        return
      }
      
      const attachedIvr = this.ivrs.find(ivr => ivr.id === this.campaignIvrId)
      
      if (!!attachedIvr) {
        this.currentIvrSlug = attachedIvr.slug
      }
    },
  },
}
</script>
