import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

/**
 *  'publisher' on back-end -> 'vendor' on front-end
 */

const getVendors = params => axios.get(`${getAccountId()}/publishers/paginated`, { params })
const getAllVendors = () => axios.get(`${getAccountId()}/publishers/all`)
const getDetailVendor = vendorId => axios.get(`${getAccountId()}/publishers/${vendorId}`)
const createVendor = formData => axios.post(`${getAccountId()}/publishers`, formData)
const updateVendor = ({ vendorId, formData }) => axios.patch(`${getAccountId()}/publishers/${vendorId}`, formData)
const attachNumbersToVendor = ({ vendorId, formData }) => axios.patch(`${getAccountId()}/publishers/${vendorId}/numbers`, formData)
const deleteVendor = vendorId => axios.delete(`${getAccountId()}/publishers/${vendorId}`)

const getConversionOptions = () => axios.get('publishers/conversion-options')
const getDuplicatePayoutOptions = () => axios.get('publishers/duplicate-payout-options')

export {
  getVendors, getAllVendors, getDetailVendor, createVendor, updateVendor, attachNumbersToVendor, deleteVendor, getConversionOptions, getDuplicatePayoutOptions
}