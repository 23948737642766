<template>
<!--  <v-menu offset-y>-->
<!--    <template #activator="{ on }">-->
<!--      <div-->
<!--        class="pl-4 py-4 pointer primary-text_hover"-->
<!--        v-on="on"-->
<!--      >POOL</div>-->
<!--    </template>-->

<!--    <div class="d-flex align-center primary py-4 px-8 opacity-80">-->
<!--      <div class="lightPrimary&#45;&#45;text font-regular pr-4">{{ number['number_pool'].name }}</div>-->

<!--      <TooltipAction bottom message="Show js code">-->
<!--        <template #activator>-->
<!--          <v-icon @click.stop="openDialogNumberPoolCode()">$eyeLightIcon</v-icon>-->
<!--        </template>-->
<!--      </TooltipAction>-->

<!--      <DialogShowJSCode-->
<!--        v-model="showJSCodeDialog"-->
<!--        :numberId="number.id"-->
<!--      />-->
<!--    </div>-->
<!--  </v-menu>-->

  <div>
    <TooltipAction bottom :message="number['number_pool'].name">
      <template #activator>
        <div
          class="pointer primary-text_hover"
          @click="openDialogNumberPoolCode()"
        >POOL</div>
      </template>
    </TooltipAction>

    <DialogShowJSCode
      v-model="showJSCodeDialog"
      :numberId="number.id"
    />
  </div>
</template>

<script>
import TooltipAction from '@/components/tooltip/TooltipAction'
import DialogShowJSCode from '@/components/dialog/DialogShowJSCode'

export default {
  name: 'MenuNumberPool',
  inheritAttrs: false,
  components: { TooltipAction, DialogShowJSCode },
  props: {
    number: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    showJSCodeDialog: false,
  }),
  methods: {
    openDialogNumberPoolCode() {
      this.showJSCodeDialog = true
    },
  },
}
</script>