<template>
  <v-hover #default="{ hover }">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      class="px-11"
      :class="hover
        ? 'copyBtnBgHover copyBtnColorHover--text'
        : 'copyBtnBg copyBtnColor--text'
      "
      :height="height"
      :ripple="false"
      depressed
    >
      <span class="font-normal">
        <slot></slot>
      </span>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'CopyButton',
  inheritAttrs: false,
  props: {
    height: {
      type: String | Number,
      default: 48
    }
  },
}
</script>