<template>
  <div
    id="tracking-pixel"
    class="tracking-pixel-table-container"
    :class="{ 'panel-disabled disable-block': !campaignSlug, 'pb-30': !visiblePanel }"
  >
    <v-row
      class="d-flex align-center pt-16 px-12 pb-20"
      :class="checkSm ? 'pt-sp-23 px-sp-20' : 'pt-sm-25 px-sm-25'"
      no-gutters
    >
      <v-col cols="12" md="3" lg="2">
        <TextDescription :description="description.pixel" />
      </v-col>
    
      <v-col cols="12" md="9" lg="10">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-wrap justify-space-between table-container pb-sm-6"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <SearchField
              v-model="search"
              class="mr-4 mr-sm-11 mb-4"
              :height="48"
            />

            <ActionOutlinedButton
              class="mb-4"
              @click="openTrackingPixelPanel"
            >Add Pixel</ActionOutlinedButton>
          </v-col>
        
          <v-col
            class="table-container"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <TrackingPixels
              :campaignSlug="campaignSlug"
              :campaignPixels.sync="pixelsLocal"
              :loadingDetailCampaign="loadingDetailCampaign"
              :search="search"
              @reloadCampaignManage="reloadCampaignManage"
              @openEditPanel="openEditPanel"
            />
          </v-col>
        </v-row>
      </v-col>
      
      <v-col
        cols="12" md="9" lg="10" offset-md="3" offset-lg="2"
      >
        <v-row no-gutters>
          <v-col
            class="pt-20"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
            v-show="!visiblePanel"
          >
            <ActionButton @click="closeCreateCampaign">Close</ActionButton>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  
    <v-slide-y-transition>
      <div
        id="tracking-pixel-panel"
        class="tracking-pixel-container expandPanelBg panel-disabled"
        v-if="visiblePanel"
      >
        <TrackingPixelPanel
          :description="description"
          :campaignSlug="campaignSlug"
          :freePixels="freePixelsLocal"
          :changedPixel="changedPixel"
          @reloadCampaignManage="reloadCampaignManage"
          @createSuccess="createSuccess"
          @updateSuccess="updateSuccess"
          @attachSuccess="attachSuccess"
          @closePanel="closePanel"
        />
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import ActionButton from '@/components/buttons/ActionButton'
import TrackingPixels from '@/components/tables/TrackingPixels'
import TrackingPixelPanel from '@/components/campaign/panels/TrackingPixelPanel'
import SearchField from '@/components/inputs/SearchField'

import { changeItemsAfterUpdate } from '@/helper/app-helper'

export default {
  name: 'CampaignTrackingPixel',
  inheritAttrs: false,
  components: { TextDescription, ActionOutlinedButton, ActionButton, TrackingPixels, TrackingPixelPanel, SearchField },
  props: {
    description: {
      type: Object,
      required: true
    },
    loadingDetailCampaign: {
      type: Boolean,
      required: true
    },
    campaignPixels: {
      type: Array,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    freePixels: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    visiblePanel: false,
    pixelsLocal: [],
    freePixelsLocal: [],
    changedPixel: null,
    search: null,
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    checkMd() {
      return this.$vuetify.breakpoint.width < 1600
    },
  },
  methods: {
    openTrackingPixelPanel() {
      this.changedPixel = null
      this.visiblePanel = true
      this.scrollTo()
    },
    closeCreateCampaign() {
      this.$router.push({ name: 'ManageCampaigns' })
    },
    openEditPanel(pixel) {
      this.changedPixel = pixel
      this.visiblePanel = true
      this.scrollTo()
    },
    createSuccess(newPixel) {
      this.freePixelsLocal.push(newPixel)
    },
    updateSuccess(changedPixel) {
      this.pixelsLocal = changeItemsAfterUpdate(this.pixelsLocal, changedPixel, 'id')
      this.closePanel()
    },
    attachSuccess(pixel) {
      this.pixelsLocal.push(pixel)
      this.closePanel()
      this.reloadCampaignManage()
    },
    reloadCampaignManage() {
      this.$emit('reloadCampaignManage')
    },
    scrollTo(value = true) {
      if (value) {
        setTimeout(() => {
          this.$vuetify.goTo('#tracking-pixel-panel')
        })
      } else {
        this.$vuetify.goTo('#tracking-pixel')
      }
    },
    closePanel() {
      this.visiblePanel = false
      this.scrollTo(false)
    },
  },
  watch: {
    campaignPixels: {
      handler(items) {
        this.pixelsLocal = items
      }
    },
    freePixels: {
      immediate: true,
      handler(items) {
        this.freePixelsLocal = items
      }
    }
  },
}
</script>
