<template>
  <v-row
    class="d-flex align-center pa-12 py-md-20"
    :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
    no-gutters
  >
    <v-col cols="12" md="3" lg="2">
      <TextDescription :description="description" />
    </v-col>
    
    <v-col cols="12" md="9" lg="10">
      <v-form ref="editVendor" v-model="validateForm">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Vendor</span>
          </v-col>
  
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <FormField
              v-model="editVendor.name"
              class="field-container"
              readonly
            >
            </FormField>
          </v-col>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Payout, $</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="payout"
            ></portal-target>
          </v-col>
          
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <InputNumberControl
              v-model="formDataEditVendor.payout"
              :rules="rules"
              :min="0"
              :step="0.01"
            >
              <template #message="{ key, message }">
                <portal to="payout" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </InputNumberControl>
          </v-col>
  
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Payout on</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <ToggleButton
              v-model="formDataEditVendor['convert_on_id']"
              :buttons="conversionOptions"
              :class="{'flex-column': checkToggleButtonWidth}"
            />
          </v-col>
          
          <v-expand-transition>
            <v-col cols="12" v-if="formDataEditVendor['convert_on_id'] === 3">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Length</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="length"
                  ></portal-target>
                </v-col>
                
                <v-col class="pb-10 d-flex align-center" cols="12" sm="8" md="9">
                  <InputNumberControl
                    v-model="optionalData.length"
                    :rules="rules"
                  >
                    <template #message="{ key, message }">
                      <portal to="length" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </InputNumberControl>
                  <div class="pl-4">
                    <span class="font-normal nameField--text">seconds</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Duplicate Payouts</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <ToggleButton
              v-model="formDataEditVendor['duplicate_id']"
              :buttons="duplicatePayoutOptions"
            />
          </v-col>
          
          <v-expand-transition>
            <v-col cols="12" v-if="formDataEditVendor['duplicate_id'] === 3">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column align-end justify-center pr-11 pb-10"
                  cols="3"
                >
                  <span class="font-normal nameField--text">Days</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="days"
                  ></portal-target>
                </v-col>
                
                <v-col class="pb-10" cols="9">
                  <InputNumberControl
                    v-model="optionalDataDuplicate['time_limit']"
                    class="field-container"
                    :rules="rules"
                  >
                    <template #message="{ key, message }">
                      <portal to="days" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </InputNumberControl>
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>
          
          <v-col class="pt-5 pt-md-10" cols="12" sm="8" md="9" offset-sm="4" offset-md="3">
            <CancelButton
              class="mr-5 mr-sm-10"
              @click="closePanel"
            >
              Cancel
            </CancelButton>
            
            <ActionButton
              @click="submitForm"
              :loading="loading"
            >
              Save and Continue
            </ActionButton>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import InputNumberControl from '@/components/inputs/InputNumberControl'
import ToggleButton from '@/components/buttons/ToggleButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { editAttachedVendor } from '@/api/campaign-routes'

export default {
  name: 'EditAttachedVendorPanel',
  inheritAttrs: false,
  components: { TextDescription, FormField, InputNumberControl, ToggleButton, CancelButton, ActionButton },
  props: {
    editVendor: {
      type: Object,
      required: true
    },
    description: {
      type: Object,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    conversionOptions: {
      type: Array,
      required: true
    },
    duplicatePayoutOptions: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    validateForm: true,
    loading: false,
    formDataEditVendor: {
      publisher: null,
      payout: 0,
      'convert_on_id': 1,
      'duplicate_id': 1
    },
    method: 'put',
    optionalData: {
      length: 10
    },
    optionalDataDuplicate: {
      'time_limit': 10
    },
    rules: [],
  }),
  computed: {
    checkToggleButtonWidth() {
      return this.$vuetify.breakpoint.width < 820
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    }
  },
  methods: {
    parseProps() {
      this.formDataEditVendor = {
        publisher: this.editVendor.slug,
        payout: +this.editVendor.pivot.payout,
        'convert_on_id': +this.editVendor.pivot['convert_on_id'],
        'duplicate_id': +this.editVendor.pivot['duplicate_id']
      }
      this.optionalData = {
        length: this.editVendor.pivot.length === null ? 10 : +this.editVendor.pivot.length
      }
      this.optionalDataDuplicate = {
        'time_limit': this.editVendor.pivot['time_limit'] === null ? 10 : +this.editVendor.pivot['time_limit']
      }
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.editVendor.validate()) {
        this.loading = true

        let formData = Object.assign({}, this.formDataEditVendor)

        if(this.formDataEditVendor['convert_on_id'] === 3) {
          formData = Object.assign(formData, this.optionalData)
        }

        if (this.formDataEditVendor['duplicate_id'] === 3) {
          formData = Object.assign(formData, this.optionalDataDuplicate)
        }
        
        const { success, payload, message } = await editAttachedVendor({
          campaignId: this.campaignSlug,
          formData
        })
        
        if (success) {
          this.$emit('editSuccess', payload)
          this.$notify({ type: 'success-bg', text: message })
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
        
        this.loading = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || v === 0 || 'This field is required'
      ]
    },
    closePanel() {
      this.$emit('closePanel')
    },
  },
  watch: {
    editVendor: {
      immediate: true,
      handler() {
        this.parseProps()
      }
    }
  }
}
</script>
