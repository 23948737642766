<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loadingDetailNumber"
    :loader-height="2"
    :options.sync="options"
    item-key="slug"
    class="elevation-0 borderNone font-small borderTableXS transparent"
    outlined
    disable-pagination
    hide-default-footer
    :header-props="{ sortIcon: '$sortDownIcon' }"
    :mobile-breakpoint="0"
  >
    <template #item.number="{ item }">
      +{{ item.number }}
    </template>
    
    <template #item.monthlyRentalRate="{ item }">
      {{ currency(item.monthlyRentalRate) }}
    </template>
    
    <template #item.actions="{ item }">
      <TooltipAction bottom message="buy number">
        <template #activator="{ on }">
          <AddButton v-on="on" @click="buyNumber(item)">+ Add</AddButton>
        </template>
      </TooltipAction>
    </template>
  </v-data-table>
</template>

<script>
import currency from '@/mixins/currency'
import TooltipAction from '@/components/tooltip/TooltipAction'
import AddButton from '@/components/buttons/AddButton'

export default {
  name: 'BuyNumber',
  components: { TooltipAction, AddButton },
  mixins: [ currency ],
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loadingDetailNumber: {
      type: Boolean,
      required: true
    },
  },
  data: () => ({
    options: {},
    headers: [
      {text: 'PHONE NUMBER', value: 'number', width: 160},
      {text: 'TYPE', value: 'type', align: 'center'},
      {text: 'REGION', value: 'region', align: 'center'},
      // {text: 'Monthly Rental Rate', value: 'monthlyRentalRate', width: 200, align: 'center'},
      {text: 'Actions', value: 'actions', sortable: false, width: 120, align: 'right'},
    ]
  }),
  methods: {
    buyNumber(number) {
      this.$emit('openDialogConfirm', number)
    },
  },
}
</script>
