<template>
  <v-tabs-items class="expandPanelBg" v-model="currentTab" touchless>
    <v-tab-item :value="tabs.add">
      <AttachNumberPanel
        :description="description.addNumber"
        :campaignSlug="campaignSlug"
        :freeNumbersAll="freeNumbers"
        @buyNumbersTab="buyNumbersTab"
        @attachSuccess="attachNumbersToCampaignSuccess"
        @closePanel="closePanel"
      />
    </v-tab-item>

    <v-tab-item :value="tabs.buy">
      <CardBuyNumbers
        class="expandPanelBg pa-12 py-md-20"
        :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
        :description="description.byNumbers"
        :countryId="currentCountryId"
        :campaignSlug="campaignSlug"
        isExpandPanel
        @reloadCampaignManage="reloadCampaignManage"
        @closePanelByNumbers="addNumbersTab"
      />
    </v-tab-item>

    <v-tab-item :value="tabs.edit">
      <EditAttachedNumberPanel
        :description="description.editNumber"
        :editNumber="editNumber"
        :campaignSlug="campaignSlug"
        :campaignVendors="campaignVendors"
        :vendors="vendors"
        :pools="pools"
        :trafficSources="trafficSources"
        :conversionOptions="conversionOptions"
        :duplicatePayoutOptions="duplicatePayoutOptions"
        @updateNumberSuccess="updateNumberSuccess"
        @poolActionSuccess="poolActionSuccess"
        @updateVendorSuccess="updateVendorSuccess"
        @closePanel="closePanel"
      />
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import AttachNumberPanel from '@/components/campaign/panels/AttachNumberPanel'
import EditAttachedNumberPanel from '@/components/campaign/panels/EditAttachedNumberPanel'
import CardBuyNumbers from '@/components/cards/CardBuyNumbers'

import { getConversionOptions, getDuplicatePayoutOptions } from '@/api/vendor-routes'
import { getAllVendors } from '@/api/vendor-routes'
import { getAllTrafficSources } from '@/api/traffic-source-routes'
import { getAllPools } from '@/api/pool-routes'

export default {
  name: 'CampaignNumberTabs',
  inheritAttrs: false,
  components: { AttachNumberPanel, EditAttachedNumberPanel, CardBuyNumbers,  },
  props: {
    description: {
      type: Object,
      required: true
    },
    editNumber: {
      default: () => {}
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    campaignVendors: {
      type: Array,
      default: () => []
    },
    freeNumbers: {
      type: Array,
      required: true
    },
    currentCountryId: {
      type: String | Number,
      default: null
    },
  },
  data: () => ({
    tabs: {
      add: 'add',
      buy: 'buy',
      edit: 'edit',
    },
    currentTab: 'add',
    pools: [],
    trafficSources: [],
    vendors: [],
    conversionOptions: [],
    duplicatePayoutOptions: [],
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  mounted() {
    this.goToLoadTrafficSource()
    this.goToLoadConversionOptions()
    this.goToLoadDuplicatePayoutOptions()
  },
  methods: {
    async goToLoadAllVendors() {
      if (this.vendors.length) {
        return
      }

      const { success, payload, message } = await getAllVendors()

      if (success) {
        this.vendors = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async goToLoadConversionOptions() {
      if (this.conversionOptions.length) {
        return
      }

      const { success, payload, message } = await getConversionOptions()

      if (success) {
        this.conversionOptions = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async goToLoadDuplicatePayoutOptions() {
      if (this.conversionOptions.length) {
        return
      }

      const { success, payload, message } = await getDuplicatePayoutOptions()

      if (success) {
        this.duplicatePayoutOptions = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async goToLoadAllPools() {
      if (this.pools.length) {
        return
      }

      const { success, payload, message } = await getAllPools()

      if (success) {
        this.pools = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async goToLoadTrafficSource() {
      if (this.trafficSources.length) {
        return
      }

      const { success, payload, message } = await getAllTrafficSources()

      if (success) {
        this.trafficSources = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    addNumbersTab() {
      this.currentTab = this.tabs.add
    },
    buyNumbersTab() {
      this.currentTab = this.tabs.buy
    },
    editNumbersTab() {
      this.goToLoadAllVendors()
      this.goToLoadAllPools()
      this.goToLoadTrafficSource()
      this.currentTab = this.tabs.edit
    },
    attachNumbersToCampaignSuccess(numbers) {
      this.$emit('attachSuccess', numbers)
    },
    reloadCampaignManage() {
      this.$emit('reloadCampaignManage')
    },
    closePanel() {
      this.$emit('closePanel')
    },
    updateNumberSuccess({ trafficSource, number }) {
      this.$emit('updateNumberSuccess', { trafficSource, number })
    },
    poolActionSuccess({ pool, numberId}) {
      this.$emit('poolActionSuccess', { pool, numberId })
    },
    updateVendorSuccess(vendor) {
      this.$emit('updateVendorSuccess', vendor)
    },
  },
  watch: {
    editNumber: {
      deep: true,
      immediate: true,
      handler(number) {
        if (!!number) {
          this.editNumbersTab()
        } else {
          this.addNumbersTab()
        }
      }
    },
  },
}
</script>