<template>
  <v-hover #default="{ hover }">
    <v-file-input
      class="text-input-field"
      v-model="fieldValue"
      v-bind="$attrs"
      v-on="$listeners"
      flat
      solo
      prepend-icon
      :clearable="false"
      truncate-length="50"
      :background-color="focusField || hover || !!value ? 'inputFocus' : ''"
      @focus="changeFocus(true)"
      @blur="changeFocus(false)"
    >
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
      
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </v-file-input>
  </v-hover>
</template>

<script>
export default {
  name: 'FileInput',
  inheritAttrs: false,
  props: {
    value: {
      type: String | Number | Object,
      default: ''
    }
  },
  data: () => ({
    focusField: false
  }),
  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    changeFocus(val) {
      this.focusField = val
    }
  }
}
</script>
