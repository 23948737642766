<template>
  <div
    id="call-routing"
    class="add-destination-container"
    :class="{ 'panel-disabled disable-block': !campaignSlug }"
  >
    <v-row
      class="d-flex pt-15 px-12 pb-20"
      :class="[checkSm ? 'pt-sp-23 px-sp-20' : 'pt-sm-25 px-sm-25', !!currentRouting ? '' : 'align-center' ]"
      no-gutters
    >
      <v-col cols="12" md="3" lg="2">
        <TextDescription
          :class="{ 'pt-7': !!currentRouting }"
          :description="currentRouting ? description.ivr : description.destination"
        />
      </v-col>
    
      <v-col cols="12" md="9" lg="10">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Routing option</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <ToggleButton
              v-model="currentRouting"
              :buttons="routingOptions"
            />
          </v-col>
        </v-row>
        
        <v-slide-y-transition leave-absolute>
          <v-row no-gutters v-if="!currentRouting">
            <v-col
              class="d-flex flex-wrap justify-space-between table-container pb-sm-6"
              :style="checkMd ? 'max-width: 1037px;' : ''"
              cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
            >
              <SearchField
                v-model="search"
                class="mr-4 mr-sm-11 mb-4"
                :height="48"
              />

              <div class="d-flex">
                <ActionOutlinedButton class="mb-4" @click="openCloseAddDestinationPanel">Add Destination</ActionOutlinedButton>

                <ActionOutlinedButton class="ml-4 ml-sm-11 mb-4" @click="openCloseAddGroupPanel">Add Group</ActionOutlinedButton>
              </div>
            </v-col>
    
            <v-col
              class="table-container"
              :style="checkMd ? 'max-width: 1037px;' : ''"
              cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
            >
              <AddedDestinations
                :campaignSlug="campaignSlug"
                :callRoutes.sync="callRoutesLocal"
                :loadingDetail="loadingDetailCampaign"
                :conversionOptions="conversionOptions"
                :search="search"
                @reloadCampaignManage="reloadCampaignManage"
                @openEditCallRoutePanel="openEditCallRoutePanel"
              />
            </v-col>

<!--            <v-col cols="12" class="divider-container pb-sm-25" :class="!visiblePanel ? 'pb-16' : 'pb-12'"></v-col>-->
            <v-col
              class="pt-20"
              :style="checkMd ? 'max-width: 1037px;' : ''"
              cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
              v-show="!visiblePanel"
            >
              <ActionButton @click="closeCreateCampaign">Close</ActionButton>
            </v-col>
<!--            <v-col-->
<!--              class="divider-container pt-sm-25"-->
<!--              :class="!visiblePanel ? 'pt-16' : 'pt-12'"-->
<!--              :style="checkMd ? 'max-width: 1037px;' : ''"-->
<!--              cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"-->
<!--            >-->
<!--              <v-divider v-if="!visiblePanel"/>-->
<!--            </v-col>-->
          </v-row>
        </v-slide-y-transition>
        
        <v-slide-y-transition leave-absolute>
          <AttachIvrPanel
            v-if="!!currentRouting"
            :campaignSlug="campaignSlug"
            :campaignIvrId.sync="currentIvrId"
            @reloadCampaignManage="reloadCampaignManage"
          />
        </v-slide-y-transition>
      </v-col>
    </v-row>
  
    <v-slide-y-transition>
      <div
        id="call-routing-panel"
        class="destination-container expandPanelBg panel-disabled"
        v-if="visiblePanel"
      >
        <CreateCallRoutePanel
          v-if="visibleCreateCallRoutePanel"
          :description="createRouteDescription"
          :campaignSlug="campaignSlug"
          :conversionOptions="conversionOptions"
          :duplicateRevenueOptions="duplicateRevenueOptions"
          :freeDestinations="freeDestinations"
          :freeGroups="freeGroups"
          :newSlug.sync="newSlugAfterCreate"
          :isGroup="isGroupCreate"
          @createSuccess="createCallRouteSuccess"
          @openCreateDestinationPanel="openCreateDestinationPanel"
          @openCreateGroupPanel="openCreateGroupPanel"
          @closePanel="closePanel"
        />
        
        <EditCallRoutePanel
          v-if="visibleEditCallRoutePanel"
          :description="editRouteDescription"
          :campaignSlug="campaignSlug"
          :conversionOptions="conversionOptions"
          :duplicateRevenueOptions="duplicateRevenueOptions"
          :editRoute="editRoute"
          :isGroup="isGroupEdit"
          @updateSuccess="updateCallRouteSuccess"
          @closePanel="closePanel"
        />
      
        <CardDestination
          v-if="visibleCreateDestinationPanel"
          class="expandPanelBg pa-12 py-md-20"
          :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
          :description="description.createDestination"
          :capOnOptionsFromRelative="capOnOptions"
          isExpandPanel
          onPageCreateCampaign
          @closePanel="closePanel"
          @createSuccess="createDestinationSuccess"
          @openCreateCallRoute="openCreateCallRoute"
        />
        
        <CardGroup
          v-if="visibleCreateGroupPanel"
          class="expandPanelBg pa-12 py-md-20"
          :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
          :description="description.createGroup"
          isExpandPanel
          onPageCreateCampaign
          @closePanel="closePanel"
          @createSuccess="createGroupSuccess"
          @openCreateCallRoute="openCreateCallRoute"
        />
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import AddedDestinations from '@/components/tables/AddedDestinations'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import ActionButton from '@/components/buttons/ActionButton'
import ToggleButton from '@/components/buttons/ToggleButton'
import CreateCallRoutePanel from '@/components/campaign/panels/CreateCallRoutePanel'
import EditCallRoutePanel from '@/components/campaign/panels/EditCallRoutePanel'
import AttachIvrPanel from '@/components/campaign/panels/AttachIvrPanel'
import CardDestination from '@/components/cards/CardDestination'
import CardGroup from '@/components/cards/CardGroup'
import SearchField from '@/components/inputs/SearchField'

import { getCapOnOptions, getConversionOptions, getDuplicateRevenueOptions } from '@/api/destination-routes'
import { detachIvrFromCampaign } from '@/api/campaign-routes'

export default {
  name: 'CampaignCallRouting',
  inheritAttrs: false,
  components: { TextDescription, AddedDestinations, ActionOutlinedButton, ActionButton, ToggleButton, CreateCallRoutePanel, EditCallRoutePanel, AttachIvrPanel, CardDestination, CardGroup, SearchField },
  props: {
    description: {
      type: Object,
      required: true
    },
    campaignDestinationsAndGroups: {
      type: Array,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    campaignIvrId: {
      type: String | Number,
      required: true
    },
    loadingDetailCampaign: {
      type: Boolean,
      required: true
    },
    freeDestinations: {
      type: Array,
      required: true
    },
    freeGroups: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    callRoutesLocal: [],
    capOnOptions: [],
    conversionOptions: [],
    duplicateRevenueOptions: [],
    visibleCreateCallRoutePanel: false,
    visibleEditCallRoutePanel: false,
    visibleCreateDestinationPanel: false,
    visibleCreateGroupPanel: false,
    newSlugAfterCreate: null,
    editRoute: null,
    editRouteDescription: null,
    createRouteDescription: null,
    isGroupCreate: false,
    isGroupEdit: false,
    currentRouting: 0,
    routingOptions: [
      { name: 'Standard routing', id: 0 },
      { name: 'IVR', id: 1 },
    ],
    currentIvrId: null,
    search: null,
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    checkMd() {
      return this.$vuetify.breakpoint.width < 1600
    },
    visiblePanel() {
      return this.visibleCreateCallRoutePanel || this.visibleEditCallRoutePanel || this.visibleCreateDestinationPanel || this.visibleCreateGroupPanel
    },
  },
  mounted() {
    this.goToLoadCapOnOptions()
    this.goToLoadConversionOptions()
    this.goToLoadDuplicateRevenueOptions()
  },
  methods: {
    async goToLoadCapOnOptions() {
      const { success, payload, message } = await getCapOnOptions()
      
      if (success) {
        this.capOnOptions = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async goToLoadConversionOptions() {
      const { success, payload, message } = await getConversionOptions()

      if (success) {
        this.conversionOptions = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async goToLoadDuplicateRevenueOptions() {
      const { success, payload, message } = await getDuplicateRevenueOptions()

      if (success) {
        this.duplicateRevenueOptions = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    openCloseAddDestinationPanel() {
      if (this.visibleCreateCallRoutePanel && !this.isGroupCreate) {
        this.closePanel()
      } else {
        this.isGroupCreate = false
        this.createRouteDescription = this.description.addDestination
        this.visibleCreateCallRoutePanel = true
        this.scrollTo()
      }
    },
    openEditCallRoutePanel(route) {
      this.editRoute = route
      this.visibleEditCallRoutePanel = true
      this.editRouteDescription = this.description.editCallRoute
      this.isGroupEdit = !!route.isGroup
      this.scrollTo()
    },
    updateCallRouteSuccess(updatedRoute) {
      const index = this.callRoutesLocal.findIndex(item => item.slug === updatedRoute.slug)

      if (index + 1) {
        this.callRoutesLocal[index].pivot = updatedRoute.pivot
      }

      this.callRoutesLocal = this.callRoutesLocal.slice()
      
      this.closePanel()
    },
    openCloseAddGroupPanel() {
      if (this.visibleCreateCallRoutePanel && this.isGroupCreate) {
        this.closePanel()
      } else {
        this.isGroupCreate = true
        this.createRouteDescription = this.description.addGroup
        this.visibleCreateGroupPanel = false
        this.visibleCreateCallRoutePanel = true
        this.scrollTo()
      }
    },
    openCreateDestinationPanel() {
      this.visibleCreateCallRoutePanel = false
      this.visibleCreateGroupPanel = false
      this.visibleCreateDestinationPanel = true
    },
    openCreateGroupPanel() {
      this.visibleCreateDestinationPanel = false
      this.visibleCreateCallRoutePanel = false
      this.visibleCreateGroupPanel = true
    },
    openCreateCallRoute() {
      this.visibleCreateDestinationPanel = false
      this.visibleCreateGroupPanel = false
      this.visibleCreateCallRoutePanel = true
    },
    createCallRouteSuccess(newCallRoutes) {
      this.reloadCampaignManage()
      this.callRoutesLocal.push(...newCallRoutes)
      this.closePanel()
    },
    createDestinationSuccess(destination) {
      this.isGroupCreate = false
      this.reloadCampaignManage()
      this.newSlugAfterCreate = destination.slug
      this.openCreateCallRoute()
      this.scrollTo()
    },
    createGroupSuccess(slug) {
      this.isGroupCreate = true
      this.reloadCampaignManage()
      this.newSlugAfterCreate = slug
      this.openCreateCallRoute()
      this.scrollTo()
    },
    reloadCampaignManage() {
      this.$emit('reloadCampaignManage')
    },
    closePanel(scroll = true) {
      this.isGroupCreate = false
      this.isGroupEdit = false
      this.visibleCreateDestinationPanel = false
      this.visibleCreateCallRoutePanel = false
      this.visibleEditCallRoutePanel = false
      this.visibleCreateGroupPanel = false
      if (scroll) {
        this.scrollTo(false)
      }
    },
    scrollTo(value = true) {
      if (value) {
        setTimeout(() => {
          this.$vuetify.goTo('#call-routing-panel')
        })
      } else {
        this.$vuetify.goTo('#call-routing')
      }
    },
    async removeIvr() {
      if (!this.currentIvrId) {
        return
      }
      
      const { success, message } = await detachIvrFromCampaign(this.campaignSlug)

      if (success) {
        this.currentIvrId = null
        this.$notify({ type: 'success-bg', text: message })
      } else {
        this.currentRouting = !!this.currentRouting ? 0 : 1
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    closeCreateCampaign() {
      this.$router.push({ name: 'ManageCampaigns' })
    },
  },
  watch: {
    campaignDestinationsAndGroups: {
      handler(items) {
        this.callRoutesLocal = items
      }
    },
    visibleCreateCallRoutePanel: {
      handler(visible) {
        if (visible) {
          this.visibleCreateDestinationPanel = false
          this.visibleEditCallRoutePanel = false
          this.visibleCreateGroupPanel = false
        }
      }
    },
    visibleEditCallRoutePanel: {
      handler(visible) {
        if (visible) {
          this.visibleCreateCallRoutePanel = false
          this.visibleCreateDestinationPanel = false
          this.visibleCreateGroupPanel = false
        }
      }
    },
    campaignIvrId: {
      handler(id) {
        if (!!id) {
          this.currentIvrId = id
        }
      }
    },
    currentIvrId: {
      handler(id) {
        this.currentRouting = !!id ? 1 : 0
      }
    },
    currentRouting: {
      handler(option) {
        if (!!option) {
          this.closePanel(false)
          this.search = ''
        } else {
          this.removeIvr()
        }
      }
    },
  },
}
</script>
