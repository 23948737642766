<template>
  <v-hover #default="{ hover }">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      class="px-8 add-btn text-capitalize"
      :class="hover
        ? 'violet violetBlack--text'
        : 'grayLighten2--text'"
      outlined
      height="38"
      :ripple="false"
    >
      <span class="font-normal">
        <slot></slot>
      </span>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'AddButton',
  inheritAttrs: false,
}
</script>
