<template>
  <v-row
    v-bind="$attrs"
    class="d-flex align-center pt-15 px-12"
    :class="checkSm ? 'pt-sp-23 px-sp-20' : 'pt-sm-25 px-sm-25'"
    no-gutters
  >
    <v-col cols="12" md="3" lg="2">
      <TextDescription :description="description" />
    </v-col>
    
    <v-col cols="12" md="9" lg="10">
      <v-form ref="form" v-model="validateForm">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Campaign name</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="campaignName"
            ></portal-target>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <FormField
              v-model="formDataCampaign.name"
              class="field-container"
              :rules="rules"
              :loading="loadingDetailCampaign"
            >
              <template #message="{ key, message }">
                <portal to="campaignName" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </FormField>
          </v-col>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Country</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="country"
            ></portal-target>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <AutocompleteField
              v-if="!campaignSlug"
              class="field-container"
              v-model="formDataCampaign['country_id']"
              :items="countries"
              item-text="name"
              item-value="id"
              :rules="rules"
              :loading="loadingDetailCampaign"
              checkBg
            >
              <template #message="{ key, message }">
                <portal to="country" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </AutocompleteField>
            
            <FormField
              v-else
              :value="getCountryNameById(formDataCampaign['country_id'])"
              :loading="loadingDetailCampaign"
              class="field-container"
              readonly
            />
          </v-col>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Timezone</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="campaignTimezone"
            ></portal-target>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <AutocompleteField
              class="field-container"
              v-model="formDataCampaign['timezone_id']"
              :items="timezones"
              item-text="display"
              item-value="id"
              :rules="rules"
              :loading="loadingDetailCampaign"
              checkBg
            >
              <template #message="{ key, message }">
                <portal to="campaignTimezone" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </AutocompleteField>
          </v-col>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Business Hours</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <SwitchLg
              v-model="hoursOfOperation"
              bgColor="switcherViolet"
            />
          </v-col>
          
          <v-expand-transition>
            <v-col
              class="pb-10 d-flex align-start flex-column"
              cols="12" sm="8" md="9" offset-sm="4" offset-md="3"
              v-if="hoursOfOperation"
            >
              <HoursOfOperationCard
                :hoursOfOperation.sync="formDataCampaign['hours_of_operation']"
                :validateHoursOfOperation.sync="validateHoursOfOperation"
              />
            </v-col>
          </v-expand-transition>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Send Duplicates to</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <ToggleButton
              v-model="formDataCampaign['prev_connected_call_route_id']"
              :buttons="optionsRoutePrevConnCalls"
              :class="{'flex-column': isMobile}"
              @change="changeDupeOptions"
            />
          </v-col>

          <v-expand-transition>
            <v-col cols="12" v-if="showStrictOption">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">
                    Strict
                  </span>
                </v-col>

                <v-col class="pb-10 my-auto" cols="12" sm="8" md="9">
                  <SwitchLg
                    v-model="formDataCampaign['dupes_strict']"
                    bgColor="switcherViolet"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>

          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">
              Handle Anonymous Calls as Duplicate
            </span>
          </v-col>
          <v-col class="pb-10 my-auto" cols="12" sm="8" md="9">
            <SwitchLg
              v-model="formDataCampaign['anonymous_as_duplicate']"
              bgColor="switcherViolet"
            />
          </v-col>
  
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">
              Payout Once Per Caller
            </span>
          </v-col>
          <v-col class="pb-10 my-auto" cols="12" sm="8" md="9">
            <SwitchLg
              v-model="formDataCampaign['payout_once_per_caller']"
              bgColor="switcherViolet"
            />
          </v-col>
  
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">
              Recording
            </span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <SwitchLg
              v-model="formDataCampaign['record_calls']"
              bgColor="switcherViolet"
            />
          </v-col>
  
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">
              Spam Filter
            </span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <SwitchLg
              v-model="filterAnonymous"
              bgColor="switcherViolet"
            />
          </v-col>
  
          <v-expand-transition>
            <v-col cols="12" v-if="filterAnonymous">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">
                    Filter Anonymous Calls as spam
                  </span>
                </v-col>
  
                <v-col class="pb-10 my-auto" cols="12" sm="8" md="9">
                  <SwitchLg
                    v-model="formDataCampaign['filter_anonymous']"
                    bgColor="switcherViolet"
                  />
                </v-col>
  
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Filter Repeat Callers</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="filterRepeatCallers"
                  ></portal-target>
                </v-col>
  
                <v-col class="pb-10 d-flex align-center" cols="12" sm="8" md="9">
                  <InputNumberControl
                    v-model="formDataCampaign['spam_time']"
                    class="field-container"
                    :rules="rules"
                    :min="0"
                  >
                    <template #message="{ key, message }">
                      <portal to="filterRepeatCallers" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </InputNumberControl>
                  <div class="pl-4">
                    <span class="font-normal nameField--text">seconds</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>

<!--          <v-col-->
<!--            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"-->
<!--            cols="12" sm="4" md="3"-->
<!--          >-->
<!--            <span class="font-normal nameField&#45;&#45;text text-sm-right">Weights sorting</span>-->
<!--          </v-col>-->
<!--          <v-col class="pb-10" cols="12" sm="8" md="9">-->
<!--            <SwitchLg-->
<!--              v-model="formDataCampaign['weights_sorting']"-->
<!--              bgColor="switcherViolet"-->
<!--            />-->
<!--          </v-col>-->
  
<!--          <v-col-->
<!--            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"-->
<!--            cols="12" sm="4" md="3"-->
<!--          >-->
<!--            <span class="font-normal nameField&#45;&#45;text text-sm-right">-->
<!--              Music on hold-->
<!--            </span>-->
<!--          </v-col>-->
<!--          <v-col class="pb-10" cols="12" sm="8" md="9">-->
<!--            <SwitchLg-->
<!--              v-model="musicOnHold"-->
<!--              bgColor="switcherViolet"-->
<!--            />-->
<!--          </v-col>-->
<!--  -->
<!--          <v-expand-transition>-->
<!--            <v-col cols="12" v-if="musicOnHold">-->
<!--              <v-row no-gutters>-->
<!--                <v-col-->
<!--                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"-->
<!--                  cols="12" sm="4" md="3"-->
<!--                >-->
<!--                  <span class="font-normal nameField&#45;&#45;text text-sm-right">Choose an Option</span>-->
<!--                </v-col>-->
<!--                <v-col class="pb-10" cols="12" sm="8" md="9">-->
<!--                  <ToggleButton-->
<!--                    v-model="currentMusicOnHoldOptions"-->
<!--                    :buttons="musicOnHoldOptions"-->
<!--                  />-->
<!--                </v-col>-->
<!--  -->
<!--                <v-scroll-y-transition hide-on-leave>-->
<!--                  <v-col cols="12" v-if="currentMusicOnHoldOptions === 0">-->
<!--                    <v-row no-gutters>-->
<!--                      <v-col-->
<!--                        class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"-->
<!--                        cols="12" sm="4" md="3"-->
<!--                      >-->
<!--                        <span class="font-normal nameField&#45;&#45;text text-sm-right">Current track</span>-->
<!--                        <portal-target-->
<!--                          class="field-message font-normal error&#45;&#45;text text-right text-sm-right"-->
<!--                          name="inviteToBuyer"-->
<!--                        ></portal-target>-->
<!--                      </v-col>-->
<!--        -->
<!--                      <v-col class="pb-10" cols="12" sm="8" md="9">-->
<!--                        <ActionOutlinedButton height="46" min-width="52" class="pr-0 pl-0" @click="playerIsOpen ? closePlayer() : openPlayer()">-->
<!--                          <v-icon v-if="playerIsOpen" color="error">$stopRedIcon</v-icon>-->
<!--                          <v-icon v-else>$playIcon</v-icon>-->
<!--                        </ActionOutlinedButton>-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                  </v-col>-->
<!--                </v-scroll-y-transition>-->
<!--  -->
<!--                <v-scroll-y-transition hide-on-leave>-->
<!--                  <v-col cols="12" v-if="currentMusicOnHoldOptions === 1">-->
<!--                    <v-row no-gutters>-->
<!--                      <v-col-->
<!--                        class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"-->
<!--                        cols="12" sm="4" md="3"-->
<!--                      >-->
<!--                        <span class="font-normal nameField&#45;&#45;text text-sm-right">Upload track</span>-->
<!--                        <portal-target-->
<!--                          class="field-message font-normal error&#45;&#45;text text-right text-sm-right"-->
<!--                          name="inviteToBuyer"-->
<!--                        ></portal-target>-->
<!--                      </v-col>-->
<!--        -->
<!--                      <v-col class="pb-10 d-flex" cols="12" sm="8" md="9">-->
<!--                        <FileInput-->
<!--                          class="expandable-field-container file-name-container"-->
<!--                          v-model="file"-->
<!--                          :loading="loadingDetailCampaign"-->
<!--                          :rules="fileRules"-->
<!--                          :small-chips="!!file"-->
<!--                          :show-size="!!file"-->
<!--                          checkBg-->
<!--                        >-->
<!--                          <template #message="{ key, message }">-->
<!--                            <portal to="inviteToBuyer" v-if="!!message">-->
<!--                              {{ message }}-->
<!--                            </portal>-->
<!--                          </template>-->
<!--                        </FileInput>-->
<!--                        -->
<!--                        <div class="d-flex align-center pl-5">-->
<!--                          <span class="font-normal nameField&#45;&#45;text">Max 2mb</span>-->
<!--                        </div>-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                  </v-col>-->
<!--                </v-scroll-y-transition>-->
<!--  -->
<!--                <v-scroll-y-transition hide-on-leave>-->
<!--                  <v-col cols="12" v-if="currentMusicOnHoldOptions === 2">-->
<!--                    <v-row no-gutters>-->
<!--                      <v-col-->
<!--                        class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"-->
<!--                        cols="12" sm="4" md="3"-->
<!--                      >-->
<!--                        <span class="font-normal nameField&#45;&#45;text text-sm-right">Track link</span>-->
<!--                        <portal-target-->
<!--                          class="field-message font-normal error&#45;&#45;text text-right text-sm-right"-->
<!--                          name="inviteToBuyer"-->
<!--                        ></portal-target>-->
<!--                      </v-col>-->
<!--        -->
<!--                      <v-col class="pb-10" cols="12" sm="8" md="9">-->
<!--                        <FormField-->
<!--                          class="field-container"-->
<!--                          v-model="link"-->
<!--                          :loading="loadingDetailCampaign"-->
<!--                          :rules="rules"-->
<!--                          checkBg-->
<!--                        >-->
<!--                          <template #message="{ key, message }">-->
<!--                            <portal to="inviteToBuyer" v-if="!!message">-->
<!--                              {{ message }}-->
<!--                            </portal>-->
<!--                          </template>-->
<!--                        </FormField>-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                  </v-col>-->
<!--                </v-scroll-y-transition>-->
<!--              </v-row>-->
<!--            </v-col>-->
<!--          </v-expand-transition>-->
          
          <v-col
            class="pt-2 pt-sm-10"
            cols="12" sm="8" md="9" offset-sm="4" offset-md="3"
          >
            <ActionButton @click="submitForm" :loading="loadingAction">
              {{ campaignSlug ? 'Update' : 'Create' }}
            </ActionButton>
          </v-col>
          <v-col
            class="divider-container pt-16 pt-sm-25"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <v-divider v-if="!!campaignSlug" />
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  
    <v-slide-x-reverse-transition>
      <AudioPlayer
        v-model="playerIsOpen"
        v-show="playerIsOpen"
        :file="audioFile"
        autoPlay
        @closePlayer="closePlayer"
      />
    </v-slide-x-reverse-transition>
  </v-row>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import InputNumberControl from '@/components/inputs/InputNumberControl'
import FileInput from '@/components/inputs/FileInput'
import AutocompleteField from '@/components/select/AutocompleteField'
import SwitchLg from '@/components/controls/SwitchLg'
import HoursOfOperationCard from '@/components/pickers/HoursOfOperationCard'
import ToggleButton from '@/components/buttons/ToggleButton'
import ActionButton from '@/components/buttons/ActionButton'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import AudioPlayer from '@/components/reporting/AudioPlayer'

import { mapState, mapGetters } from 'vuex'
import { Types as indexTypes } from '@/store'
import { getCampaignOptions, createCampaign, updateCampaign, uploadMusicOnHoldCampaign } from '@/api/campaign-routes'

export default {
  name: 'CampaignSetup',
  inheritAttrs: false,
  components: { TextDescription, FormField, InputNumberControl, FileInput, AutocompleteField, SwitchLg, HoursOfOperationCard, ToggleButton, ActionButton, ActionOutlinedButton, AudioPlayer },
  props: {
    description: {
      type: Object,
      required: true
    },
    campaignSetupData: {
      type: Object,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    loadingDetailCampaign: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    loadingAction: false,
    validateForm: true,
    validateHoursOfOperation: true,
    formDataCampaign: {
      name: null,
      'country_id': null,
      'status_id': 1,
      'timezone_id': null,
      'hours_of_operation': null,
      'prev_connected_call_route_id': 1,
      'dupes_strict': false,
      'target_dial_attempts': 1,
      'anonymous_as_duplicate': false,
      'payout_once_per_caller': false,
      'record_calls': false,
      'filter_anonymous': false,
      'spam_time': 0,
    },
    showStrictOption: false,
    weightOptions: [
      { name: 'Off', id: 0 },
      { name: 'On', id: 1 },
    ],
    optionsRoutePrevConnCalls: [],
    hoursOfOperation: false,
    filterAnonymous: false,
    musicOnHold: false,
    playerIsOpen: false,
    currentMusicOnHoldOptions: 1,
    currentTrackLink: null,
    file: null,
    link: null,
    audioFile: null,
    rules: [],
    fileRules: [],
  }),
  computed: {
    ...mapState({
      countries: state => state.countries,
      timezones: state => state.timezones,
    }),
    ...mapGetters({
      getCountryNameById: indexTypes.getters.GET_COUNTRY_NAME_BY_ID
    }),
    isMobile() {
      return this.$vuetify.breakpoint.width < 430
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    checkMd() {
      return this.$vuetify.breakpoint.width < 1600
    },
    musicOnHoldOptions() {
      return !!this.currentTrackLink ? [
        { name: 'Current track', id: 0 },
        { name: 'Upload track', id: 1 },
        { name: 'Add link', id: 2 },
      ] : [
        { name: 'Upload track', id: 1 },
        { name: 'Add link', id: 2 },
      ]
    },
  },
  mounted() {
    this.goToLoadCampaignOptions()
  },
  methods: {
    async goToLoadCampaignOptions() {
      const { success, payload, message } = await getCampaignOptions()

      if (success) {
        this.optionsRoutePrevConnCalls = payload['route_prev_conn_calls']
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    changeDupeOptions(opt) {
      if (opt === 3) {
        this.showStrictOption = true
      } else {
        this.showStrictOption = false
        this.formDataCampaign['dupes_strict'] = false
      }
    },
    parseProps() {
      Object.keys(this.formDataCampaign).forEach(name => {
        this.formDataCampaign[name] = this.campaignSetupData[name]
      })
      this.showStrictOption = this.formDataCampaign['prev_connected_call_route_id'] === 3
      this.hoursOfOperation = !!this.formDataCampaign['hours_of_operation']
      this.filterAnonymous = !!this.formDataCampaign['filter_anonymous'] || !!this.formDataCampaign['spam_time']
      this.currentTrackLink = this.campaignSetupData['tone_music_url']
    },
    async submitForm() {
      this.hoursOfOperation || (this.validateHoursOfOperation = true)
      await this.validationForm()
      if (this.$refs.form.validate() && this.validateHoursOfOperation) {
        
        if (!!this.campaignSlug) {
          this.goToUpdateCampaign()
        } else {
          this.goToCreateCampaign()
        }
      }
    },
    async goToUpdateCampaign() {
      this.loadingAction = true

      const { success, message } = await updateCampaign({
        campaignId: this.campaignSlug,
        formData: this.formDataCampaign,
      })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        
        if ((this.musicOnHold && this.currentMusicOnHoldOptions !== 0) || (!this.musicOnHold && !!this.currentTrackLink)) {
          this.goToUpdateMusicOnHold()
        }
        
        this.rules = []
        this.fileRules = []
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAction = false
    },
    async goToCreateCampaign() {
      this.loadingAction = true

      const { success, payload, message } = await createCampaign(this.formDataCampaign)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        
        if (this.musicOnHold) {
          this.goToUpdateMusicOnHold(payload.slug)
        } else {
          this.goToDetailCampaign(payload.slug)
        }
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    goToDetailCampaign(campaignSlug) {
      this.$router.push({ query: { 'afterCreate': 'true' }, name: 'DetailCampaign', params: { slug: campaignSlug }})
    },
    async goToUpdateMusicOnHold(campaignSlug = null) {
      let formData = {}
      const config = {}
      
      if (this.musicOnHold) {
        if (this.currentMusicOnHoldOptions === 1) {
          formData = new FormData()
          formData.append('tone_music_file', this.file)
          config.headers = {
            'content-type': 'multipart/form-data'
          }
        } else if (this.currentMusicOnHoldOptions === 2) {
          formData['tone_music_url'] = this.link
        }
      }
      
      const { success, payload, message } = await uploadMusicOnHoldCampaign({
        campaignId: campaignSlug ? campaignSlug : this.campaignSlug,
        formData,
        config,
      })
      
      if (success) {
        this.currentTrackLink = payload['tone_music_url']
        this.currentMusicOnHoldOptions = this.currentTrackLink ? 0 : 1
        this.file = null
        this.link = null
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      if (campaignSlug) {
        this.goToDetailCampaign(campaignSlug)
      }
    },
    validationForm() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
      this.fileRules = [
        v => !!v ||  'This field is required',
        v => !!v && v.size < 2048000 || 'File size should be less than 2 MB!'
      ]
    },
    openPlayer() {
      this.audioFile = this.currentTrackLink
      this.playerIsOpen = true
    },
    closePlayer() {
      this.audioFile = null
      this.playerIsOpen = false
    },
  },
  watch: {
    campaignSetupData: {
      handler(data) {
        if (Object.keys(data).length) {
          this.parseProps()
        }
      }
    },
    hoursOfOperation: {
      handler(value) {
        if (!value) {
          this.formDataCampaign['hours_of_operation'] = null
        }
      }
    },
    filterAnonymous: {
      handler(value) {
        if (value) {
          this.formDataCampaign['spam_time'] = !!this.formDataCampaign['spam_time']
            ? this.formDataCampaign['spam_time']
            : 10
        } else {
          this.formDataCampaign['filter_anonymous'] = false
          this.formDataCampaign['spam_time'] = 0
        }
      }
    },
    currentTrackLink: {
      handler(link) {
        if (!!link) {
          this.musicOnHold = true
          this.currentMusicOnHoldOptions = 0
        } else {
          this.musicOnHold = false
          this.currentMusicOnHoldOptions = 1
        }
      }
    },
    musicOnHold: {
      handler(option) {
        if (!option) {
          if (this.currentTrackLink) {
            this.currentMusicOnHoldOptions = 0
          } else {
            this.currentMusicOnHoldOptions = 1
          }
          this.link = null
          this.file = null
        }
      }
    },
    currentMusicOnHoldOptions: {
      handler() {
        this.closePlayer()
      }
    },
  },
}
</script>
