<template>
  <div
    class="pa-12 py-md-20"
    :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
  >
    <v-row
      class="d-flex align-center"
      no-gutters
    >
      <v-col cols="12" md="3" lg="2">
        <TextDescription :description="description" />
      </v-col>

      <v-col cols="12" md="9" lg="10">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Number</span>
          </v-col>

          <v-col class="pb-10" cols="12" sm="8" md="9">
            <FormField
              v-model="editNumber.number"
              class="field-container"
              readonly
            />
          </v-col>

          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Number Name</span>
          </v-col>

          <v-col class="pb-10" cols="12" sm="8" md="9">
            <FormField
              v-model="formData.name"
              class="field-container"
            />
          </v-col>

          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Traffic Source</span>
          </v-col>

          <v-col class="pb-10" cols="12" sm="8" md="9">
            <AutocompleteField
              class="field-container"
              v-model="formData['traffic_source']"
              :items="trafficSources"
              item-text="name"
              item-value="id"
              check-bg
              clearable
            />
          </v-col>

          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Number Pool</span>
          </v-col>

          <v-col class="pb-10" cols="12" sm="8" md="9">
            <AutocompleteField
              class="field-container"
              v-model="formDataPoll['pool_id']"
              :items="pools"
              item-text="name"
              item-value="id"
              check-bg
              clearable
            />
          </v-col>

          <v-col
            class="d-flex align-center justify-sm-end pr-sm-11 pt-6 pb-4 pb-sm-8"
            cols="12" sm="4" md="3"
          >
            <span class="font-large sectionTitle--text text-sm-right">VENDOR SETTINGS</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9"></v-col>

          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Select Vendor</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="selectVendor"
            ></portal-target>
          </v-col>

          <v-col class="pb-10" cols="12" sm="8" md="9">
            <AutocompleteField
              class="field-container"
              v-model="formDataVendor.publisher"
              :items="vendors"
              item-text="name"
              item-value="slug"
              check-bg
              clearable
              @change="changeVendor"
            >
              <template #message="{ key, message }">
                <portal to="selectVendor" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </AutocompleteField>
          </v-col>

          <v-expand-transition>
            <v-col cols="12" v-if="!!formDataVendor.publisher">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Payout, min $1</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="payout"
                  ></portal-target>
                </v-col>

                <v-col class="pb-10" cols="12" sm="8" md="9">
                  <InputNumberControl
                    v-model="formDataVendor.payout"
                    :rules="rules"
                    :min="1"
                    :step="0.01"
                  >
                    <template #message="{ key, message }">
                      <portal to="payout" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </InputNumberControl>
                </v-col>

                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Payout on</span>
                </v-col>
                <v-col class="pb-10" cols="12" sm="8" md="9">
                  <ToggleButton
                    v-model="formDataVendor['convert_on_id']"
                    :buttons="conversionOptions"
                    :class="{'flex-column': checkToggleButtonWidth}"
                  />
                </v-col>

                <v-expand-transition>
                  <v-col cols="12" v-if="formDataVendor['convert_on_id'] === 3">
                    <v-row no-gutters>
                      <v-col
                        class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                        cols="12" sm="4" md="3"
                      >
                        <span class="font-normal nameField--text text-sm-right">Length</span>
                        <portal-target
                          class="field-message font-normal error--text text-sm-right"
                          name="length"
                        ></portal-target>
                      </v-col>

                      <v-col class="pb-10 d-flex align-center" cols="12" sm="8" md="9">
                        <InputNumberControl
                          v-model="optionalData.length"
                          :rules="rules"
                        >
                          <template #message="{ key, message }">
                            <portal to="length" v-if="!!message">
                              {{ message }}
                            </portal>
                          </template>
                        </InputNumberControl>
                        <div class="pl-4">
                          <span class="font-normal nameField--text">seconds</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-expand-transition>

                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Duplicate Payouts</span>
                </v-col>
                <v-col class="pb-10" cols="12" sm="8" md="9">
                  <ToggleButton
                    v-model="formDataVendor['duplicate_id']"
                    :buttons="duplicatePayoutOptions"
                  />
                </v-col>

                <v-expand-transition>
                  <v-col cols="12" v-if="formDataVendor['duplicate_id'] === 3">
                    <v-row no-gutters>
                      <v-col
                        class="d-flex flex-column align-end justify-center pr-11 pb-10"
                        cols="3"
                      >
                        <span class="font-normal nameField--text">Days</span>
                        <portal-target
                          class="field-message font-normal error--text text-sm-right"
                          name="days"
                        ></portal-target>
                      </v-col>

                      <v-col class="pb-10" cols="9">
                        <InputNumberControl
                          v-model="optionalDataDuplicate['time_limit']"
                          class="field-container"
                          :rules="rules"
                        >
                          <template #message="{ key, message }">
                            <portal to="days" v-if="!!message">
                              {{ message }}
                            </portal>
                          </template>
                        </InputNumberControl>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-expand-transition>
              </v-row>
            </v-col>
          </v-expand-transition>

          <v-col class="pt-5 pt-md-10" cols="12" sm="8" md="9" offset-sm="4" offset-md="3">
            <CancelButton
              class="mr-5 mr-sm-10"
              @click="closePanel"
            >
              Cancel
            </CancelButton>

            <ActionButton
              @click="submitForm"
              :loading="loading"
            >
              Save
            </ActionButton>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

<!--    <v-row-->
<!--      class="setting-content d-block d-md-flex align-center"-->
<!--      no-gutters-->
<!--    >-->
<!--      <v-col cols="12" md="9" xl="10" offset="0" offset-md="3" offset-xl="2">-->
<!--        <v-row no-gutters>-->
<!--          -->
<!--        </v-row>-->
<!--      </v-col>-->
<!--    </v-row>-->
  </div>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import AutocompleteField from '@/components/select/AutocompleteField'
import FormField from '@/components/inputs/FormField'
import InputNumberControl from '@/components/inputs/InputNumberControl'
import ToggleButton from '@/components/buttons/ToggleButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { attachVendorToCampaign, editAttachedVendor } from '@/api/campaign-routes'
import { updateNumber } from '@/api/number-routes'
import { attachPoolToNumber, detachPoolFromNumber } from '@/api/pool-routes'

export default {
  name: 'EditAttachedNumberPanel',
  inheritAttrs: false,
  components: { TextDescription, AutocompleteField, FormField, InputNumberControl, ToggleButton, CancelButton, ActionButton },
  props: {
    editNumber: {
      default: () => {}
    },
    description: {
      type: Object,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    campaignVendors: {
      type: Array,
      default: () => []
    },
    vendors: {
      type: Array,
      default: () => []
    },
    pools: {
      type: Array,
      default: () => []
    },
    trafficSources: {
      type: Array,
      default: () => []
    },
    conversionOptions: {
      type: Array,
      required: true
    },
    duplicatePayoutOptions: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    loading: false,
    formData: {
      name: '',
      'traffic_source': null,
    },
    formDataVendor: {
      publisher: null,
      payout: 1,
      'convert_on_id': 1,
      'duplicate_id': 1
    },
    method: 'put',
    optionalData: {
      length: 10
    },
    optionalDataDuplicate: {
      'time_limit': 10
    },
    formDataPoll: {
      'pool_id': null,
    },
    defaultPivot: {
      payout: 1,
      'convert_on_id': 1,
      'duplicate_id': 1,
    },
    rules: [],
    counter: 0,
  }),
  computed: {
    checkToggleButtonWidth() {
      return this.$vuetify.breakpoint.width < 820
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    localDescription() {
      return !!this.editNumber ? {
        title: this.description.title,
        text: [`+${this.editNumber.number}`]
      } : this.description
    },
  },
  methods: {
    parseProps() {
      this.formData.name = this.editNumber.name

      this.writeVendorOptions(!!this.editNumber.publisher ? this.editNumber.publisher.slug : null, this.editNumber.pivot)

      this.formDataPoll['pool_id'] = this.editNumber['number_pool_id']
      this.formData['traffic_source'] = this.editNumber['traffic_source_id']
    },
    writeVendorOptions(vendorSlug, pivot) {
      if (pivot) {
        this.formDataVendor = {
          publisher: vendorSlug,
          payout: +pivot.payout,
          'convert_on_id': +pivot['convert_on_id'],
          'duplicate_id': +pivot['duplicate_id']
        }
        this.optionalData = {
          length: !pivot.length ? 10 : +pivot.length
        }
        this.optionalDataDuplicate = {
          'time_limit': !pivot['time_limit'] ? 10 : +pivot['time_limit']
        }
      } else {
        this.formDataVendor = {
          publisher: null,
          payout: 0,
          'convert_on_id': 1,
          'duplicate_id': 1
        }
      }
    },
    changeVendor(vendorSlug) {
      if (vendorSlug) {
        const foundVendor = this.campaignVendors.find(v => v.slug === vendorSlug)

        if (foundVendor) {
          this.writeVendorOptions(vendorSlug, foundVendor.pivot)
        } else {
          this.writeVendorOptions(vendorSlug, this.defaultPivot)
        }
      }
    },
    submitForm() {
      this.loading = true

      this.updateCounter()

      this.updateNumber()
    },
    async updateNumber() {
      const formData = Object.assign({}, this.formData, { publisher: this.formDataVendor.publisher })

      const { success, payload, message } = await updateNumber({
        numberId: this.editNumber.id,
        formData
      })

      payload.publisher = this.formDataVendor.publisher ? this.vendors.find(v => v.slug === this.formDataVendor.publisher) : null

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('updateNumberSuccess', {
          trafficSource: !!formData['traffic_source'] ? this.trafficSources.find(t => t.id === formData['traffic_source']) : null,
          number: payload
        })
        this.counter--

        if (this.editNumber['number_pool_id'] !== this.formDataPoll['pool_id']) {
          this.savePool()
        }

        if (!!this.formDataVendor.publisher) {
          this.saveVendorSettings()
        }
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    async savePool() {
      const { success, message } = !!this.formDataPoll['pool_id']
        ? await attachPoolToNumber({ 'pool_id': this.formDataPoll['pool_id'], 'number_id': this.editNumber.id })
        : await detachPoolFromNumber({ 'pool_id': this.editNumber['number_pool_id'], 'number_id': this.editNumber.id })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('poolActionSuccess', {
          pool: !!this.formDataPoll['pool_id'] ? this.pools.find(p => p.id === this.formDataPoll['pool_id']) : null,
          numberId: this.editNumber.id
        })
        this.counter--
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async saveVendorSettings() {
      let formData = Object.assign({}, this.formDataVendor)

      if (this.formDataVendor['convert_on_id'] === 3) {
        formData = Object.assign(formData, this.optionalData)
      }

      if (this.formDataVendor['duplicate_id'] === 3) {
        formData = Object.assign(formData, this.optionalDataDuplicate)
      }

      const isVendorAttached = !!this.campaignVendors.find(v => v.slug === this.formDataVendor.publisher)

      const { success, payload, message } = isVendorAttached
        ? await editAttachedVendor({ campaignId: this.campaignSlug, formData })
        : await attachVendorToCampaign({ campaignId: this.campaignSlug, formData })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('updateVendorSuccess', payload)
        this.counter--
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    updateCounter() {
      this.counter = 1

      if (!!this.formDataVendor.publisher) {
        this.counter += 1
      }

      if (this.editNumber['number_pool_id'] !== this.formDataPoll['pool_id']) {
        this.counter += 1
      }
    },
    closePanel() {
      this.$emit('closePanel')
    },
  },
  watch: {
    editNumber: {
      immediate: true,
      handler(editNumber) {
        if (!!editNumber) {
          this.parseProps()
        }
      }
    },
    counter: {
      handler(counter) {
        if (counter === 0) {
          this.closePanel()
        }
      }
    },
  },
}
</script>