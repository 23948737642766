<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    class="elevation-0 borderNone font-small tableBg borderTableXS"
    outlined
    disable-pagination
    hide-default-footer
    :header-props="{ sortIcon: '$sortDownIcon' }"
    :loading="loadingDetailCampaign"
    :loader-height="2"
    :mobile-breakpoint="0"
  >
    <template #item.actions="{ item }">
      <TooltipAction bottom message="Edit">
        <template #activator>
          <v-icon class="mr-6" @click="openEditPanel(item)">$pencilIcon</v-icon>
        </template>
      </TooltipAction>
      
      <TooltipAction bottom message="Delete">
        <template #activator>
          <v-icon @click="detachPixel(item)">$deleteIcon</v-icon>
        </template>
      </TooltipAction>
    </template>
    
    <template #no-data>
      <div class="d-flex">
        <span class="font-normal text-none text-left">No Pixels linked to this Campaign</span>
      </div>
    </template>

    <template #no-results>
      <div class="d-flex">
        <span class="font-normal text-none text-left">No results found</span>
      </div>
    </template>
  
    <template #top>
      <DialogConfirm
        v-model="confirmDialog"
        :loading="loadingAction"
        :title="dialogTitle"
        :subTitle="dialogSubTitle"
        :message="dialogMessage"
        @actionSuccess="goToDeletePixel"
      />
    </template>
  </v-data-table>
</template>

<script>
import TooltipAction from '@/components/tooltip/TooltipAction'
import DialogConfirm from '@/components/dialog/DialogConfirm'

import { detachPixelFromCampaign } from '@/api/campaign-routes'

export default {
  name: 'TrackingPixels',
  inheritAttrs: false,
  components: { TooltipAction, DialogConfirm },
  props: {
    campaignPixels: {
      type: Array,
      required: true
    },
    loadingDetailCampaign: {
      type: Boolean,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    search: {
      type: String | Number,
      default: ''
    },
  },
  data: () => ({
    headers: [
      { text: 'NAME', value: 'name' },
      { text: 'FIRE PIXEL ON', value: 'event_name' },
      { text: 'URL', value: 'url' },
      { text: 'ACTIONS', value: 'actions', align: 'right', sortable: false },
    ],
    confirmDialog: false,
    loadingAction: false,
    changedItem: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
  }),
  computed: {
    items: {
      get() {
        return this.campaignPixels
      },
      set(items) {
        this.$emit('update:campaignPixels', items)
      },
    },
  },
  methods: {
    openEditPanel(pixel) {
      this.$emit('openEditPanel', pixel)
    },
    detachPixel(pixel) {
      this.changedItem = pixel
      this.dialogTitle = 'Detach pixel'
      this.dialogSubTitle = `${pixel.name}`
      this.dialogMessage = 'Are you sure you want to detach this pixel?'
      this.confirmDialog = true
    },
    async goToDeletePixel() {
      this.loadingAction = true
      
      const { success, message } = await detachPixelFromCampaign({
        campaignId: this.campaignSlug,
        params: { 'pixel_id': this.changedItem.id }
      })

      if (success) {
        !!message && this.$notify({ type: 'success-bg', text: message })
        this.afterDelete(this.changedItem.id)
        this.confirmDialog = false
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAction = false
    },
    afterDelete(deletedId) {
      this.items = this.items.filter(item => item.id !== deletedId)
      this.$emit('reloadCampaignManage')
    },
  },
}
</script>
