<template>
  <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
    <v-row no-gutters>
      <v-col cols="12" sm="4" class="pb-8 pr-11">
        <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
          {{ description.title }}
        </div>
      </v-col>
    </v-row>

    <v-form ref="attachNumber" v-model="validateForm">
      <v-row no-gutters>
        <v-col
          class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
          cols="12" sm="4"
        >
          <span class="font-normal nameField--text text-sm-right">Select numbers</span>
          <portal-target
            class="field-message font-normal error--text text-sm-right"
            name="buyerDialogName"
          ></portal-target>
        </v-col>

        <v-col class="d-flex flex-wrap" cols="12" sm="8">
          <AutocompleteField
            class="field-container mr-sp-11 pb-5"
            v-model="changedNumbers"
            :items="freeNumbersAll"
            item-text="number"
            item-value="id"
            multiple
            return-object
            checkBg
            :rules="rulesArray"
          >
            <template #message="{ key, message }">
              <portal to="attachNumbers" v-if="!!message">
                {{ message }}
              </portal>
            </template>

            <template #selection="{ item, index }">
              <span class="nameField--text pr-4" v-if="index === 0">
                {{ getCntNumbers }}
              </span>
            </template>
          </AutocompleteField>

          <div class="pb-10">
            <ActionOutlinedButton
              @click="buyNumberTab"
              height="46"
            >
              Buy New Number
            </ActionOutlinedButton>
          </div>
        </v-col>

        <v-col class="d-flex flex-wrap pt-10" cols="12" sm="8" offset-sm="4">
          <CancelButton @click="closeDialog">Cancel</CancelButton>

          <ActionButton class="ml-5 ml-sm-11" :loading="loading" @click="submitForm">
            Add Number{{ this.changedNumbers.length > 1 ? 's' : '' }}
          </ActionButton>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import AutocompleteField from '@/components/select/AutocompleteField'
import { attachNumberToCampaign } from '@/api/campaign-routes'

export default {
  name: 'AttachNumberCard',
  inheritAttrs: false,
  components: { ActionOutlinedButton, CancelButton, ActionButton, AutocompleteField },
  props: {
    description: {
      type: Object,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    freeNumbersAll: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    validateForm: true,
    loading: false,
    rulesArray: [],
    changedNumbers: [],
  }),
  computed: {
    getCntNumbers() {
      const cnt = this.changedNumbers.length

      return `${cnt} number${cnt - 1 ? 's' : ''}`
    },
  },
  methods: {
    buyNumberTab() {
      this.$emit('buyNumbersTab')
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.attachNumber.validate()) {
        this.loading = true

        const { success, payload, message } = await attachNumberToCampaign({
          campaignId: this.campaignSlug,
          formData: { numbers: this.changedNumbers.map(n => n.id) }
        })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.$emit('attachSuccess', this.changedNumbers)
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    validationForm() {
      this.rulesArray = [
        v => !!v && !!v.length || 'This field is required'
      ]
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
  },
}
</script>