<template>
  <div class="input-number-for-table-container">
    <v-text-field
      v-bind="$attrs"
      v-on="$listeners"
      v-model="fieldValue"
      class="input-number-for-table font-normal"
      color="tableBodyText"
      dense
    />
  </div>
</template>

<script>
export default {
  name: 'InputForTable',
  inheritAttrs: false,
  props: {
    value: {
      type: Number | String,
      required: true
    },
  },
  data: () => ({
    focusField: false,
  }),
  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('update', value)
      }
    },
  },
}
</script>