<template>
  <v-hover #default="{ hover }">
    <v-select
      class="select-input-field font-normal"
      :class="{ 'active-search': focusSelect || hover }"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="currentValue"
      :items="items"
      flat
      solo
      append-icon="mdi-chevron-down"
      item-color="selectItem"
      :color="!focusSelect && !hover ? 'inputFocus' : ''"
      :menu-props="localMenuProps"
      :background-color="bgField(hover)"
      @focus="changeFocus(true)"
      @blur="changeFocus(false)"
    >
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </v-select>
  </v-hover>
</template>

<script>
export default {
  name: 'SelectField',
  inheritAttrs: false,
  props: {
    value: {
      type: String | Number | Object,
      required: true
    },
    items: {
      type: Array,
      default: () => []
    },
    checkBg: {
      type: Boolean,
      default: false
    },
    menuProps: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    focusSelect: false
  }),
  computed: {
    currentValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    localMenuProps() {
      return Object.assign({}, { contentClass: 'form-select-menu'}, this.menuProps)
    },
    selectValue: {
      get() {
        return this.items
      },
      set(items) {
        this.$emit('input', items)
      }
    }
  },
  methods: {
    changeFocus(val) {
      this.focusSelect = val
    },
    bgField(hover) {
      return this.focusSelect || hover || (this.checkBg && !!this.currentValue && (!Array.isArray(this.currentValue) || !!this.currentValue.length)) ? 'inputFocus' : ''
    }
  }
}
</script>
