<template>
  <div class="priority-wrapper d-flex" :class="{ 'loading': loadingStatus }" v-click-outside="forceUpdate">
    <InputForTable
      v-model="fieldValue"
      class="hide-line"
      @update="updateValue"
    />
  </div>
</template>

<script>
import InputForTable from '@/components/inputs/InputForTable'

export default {
  name: 'TableInputControl',
  inheritAttrs: false,
  components: { InputForTable },
  props: {
    value: {
      type: Number | String,
      required: true
    },
    loadingStatus: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    interval: null,
  }),
  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    updateValue(value) {
      this.fieldValue = value
      this.setTimer()
    },
    setTimer() {
      clearInterval(this.interval)

      this.interval = setInterval(() => {
        this.saveChanges()
      }, 1500)
    },
    forceUpdate() {
      if (!this.interval) {
        return
      }

      this.saveChanges()
    },
    saveChanges() {
      clearInterval(this.interval)
      this.interval = null
      this.$emit('changeValueAction', this.fieldValue)
    },
  },
  beforeDestroy() {
    this.forceUpdate()
  },
}
</script>