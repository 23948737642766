<template>
  <div
    id="tracking-number"
    class="tracking-number-container"
    :class="{ 'panel-disabled disable-block': !campaignSlug }"
  >
    <v-row
      class="d-flex align-center pt-16 px-12"
      :class="checkSm ? 'pt-sp-23 px-sp-20' : 'pt-sm-25 px-sm-25'"
      no-gutters
    >
      <v-col cols="12" md="3" lg="2">
        <TextDescription :description="description.trackingNumber" />
      </v-col>
    
      <v-col cols="12" md="9" lg="10">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-wrap justify-space-between table-container pb-sm-6"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <SearchField
              v-model="search"
              class="mr-4 mr-sm-11 mb-4"
              :height="48"
            />

            <div class="d-flex">
              <ActionOutlinedButton class="mb-4" @click="openCloseByNumberPanel">Buy Number</ActionOutlinedButton>

              <ActionOutlinedButton class="ml-4 ml-sm-11 mb-4" @click="openCloseAddVendorPanel">Add Vendor</ActionOutlinedButton>
            </div>
          </v-col>
        
          <v-col
            class="table-container"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <TrackingNumber
              :campaignSlug="campaignSlug"
              :campaignVendors.sync="campaignVendorsLocal"
              :loadingDetailCampaign="loadingDetailCampaign"
              :freeNumbers="freeNumbers"
              :search="search"
              @reloadCampaignManage="reloadCampaignManage"
              @openTrackingNumberEditPanel="openTrackingNumberEditPanel"
            />
          </v-col>
        
          <v-col
            class="divider-container pt-sm-25"
            :class="!visiblePanel ? 'pt-16' : 'pt-12'"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <v-divider v-if="!visiblePanel"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  
    <v-slide-y-transition>
      <div
        id="vendor-panel"
        class="vendor-container expandPanelBg panel-disabled"
        v-if="visiblePanel"
      >
        <AttachVendorPanel
          v-if="visibleAddVendorPanel"
          :description="description.addVendor"
          :campaignSlug="campaignSlug"
          :conversionOptions="conversionOptions"
          :duplicatePayoutOptions="duplicatePayoutOptions"
          :freeVendors="freeVendors"
          :freeNumbersAll="freeNumbers"
          @attachSuccess="attachVendorToCampaignSuccess"
          @openByNumberPanel="openByNumberFromVendorPanel"
          @reloadCampaignManage="reloadCampaignManage"
          @closePanel="openCloseAddVendorPanel"
        />
      
        <EditAttachedVendorPanel
          v-if="visibleEditTrackingNumberPanel"
          :editVendor="editVendor"
          :description="description.editVendor"
          :campaignSlug="campaignSlug"
          :conversionOptions="conversionOptions"
          :duplicatePayoutOptions="duplicatePayoutOptions"
          @editSuccess="editAttachedVendorSuccess"
          @closePanel="closePanel"
        />
      
        <CardBuyNumbers
          v-if="visibleByNumberPanel"
          class="expandPanelBg pa-12 py-md-20"
          :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
          :description="description.byNumbers"
          :countryId="currentCountryId"
          :campaignSlug="campaignSlug"
          :vendorsFromCampaign="campaignVendorsLocal"
          isExpandPanel
          @attachSuccess="updateCampaignDetail"
          @reloadCampaignManage="reloadCampaignManage"
          @closePanelByNumbers="closeByNumbersPanel"
        />
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import TrackingNumber from '@/components/tables/TrackingVendorNumber'
import AttachVendorPanel from '@/components/campaign/panels/AttachVendorPanel'
import EditAttachedVendorPanel from '@/components/campaign/panels/EditAttachedVendorPanel'
import CardBuyNumbers from '@/components/cards/CardBuyNumbers'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import SearchField from '@/components/inputs/SearchField'

import { getConversionOptions, getDuplicatePayoutOptions } from '@/api/vendor-routes'

export default {
  name: 'CampaignTrackingVendorNumber',
  inheritAttrs: false,
  components: { TextDescription, ActionOutlinedButton, TrackingNumber, AttachVendorPanel, EditAttachedVendorPanel, CardBuyNumbers, DialogConfirm, SearchField },
  props: {
    description: {
      type: Object,
      required: true
    },
    campaignVendors: {
      type: Array,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    currentCountryId: {
      type: String | Number,
      default: null
    },
    loadingDetailCampaign: {
      type: Boolean,
      required: true
    },
    freeVendors: {
      type: Array,
      required: true
    },
    freeNumbers: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    visibleAddVendorPanel: false,
    visibleByNumberPanel: false,
    visibleEditTrackingNumberPanel: false,
    openedFromVendorPanel: false,
    conversionOptions: [],
    duplicatePayoutOptions: [],
    campaignVendorsLocal: [],
    editVendor: null,
    search: null,
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    checkMd() {
      return this.$vuetify.breakpoint.width < 1600
    },
    visiblePanel() {
      return this.visibleAddVendorPanel || this.visibleByNumberPanel || this.visibleEditTrackingNumberPanel
    }
  },
  methods: {
    async goToLoadConversionOptions() {
      if (this.conversionOptions.length) {
        return
      }
      
      const { success, payload, message } = await getConversionOptions()

      if (success) {
        this.conversionOptions = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async goToLoadDuplicatePayoutOptions() {
      if (this.conversionOptions.length) {
        return
      }

      const { success, payload, message } = await getDuplicatePayoutOptions()

      if (success) {
        this.duplicatePayoutOptions = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    reloadCampaignManage() {
      this.$emit('reloadCampaignManage')
    },
    attachVendorToCampaignSuccess({ numbers, vendor }) {
      vendor.numbers = numbers.map(number => ({
        ...number,
        'campaign_id': vendor.pivot['campaign_id'],
        'publisher_id': vendor.id,
      }))

      this.campaignVendorsLocal.push(vendor)
      this.reloadCampaignManage()
      this.openCloseAddVendorPanel()
    },
    updateCampaignDetail({ number, vendorSlug }) {
      const index = this.getVendorIndex(vendorSlug)

      if (index + 1) {
        this.campaignVendorsLocal[index].numbers.push(number)
      }
      
      this.reloadCampaignManage()
    },
    getVendorIndex(vendorSlug) {
      return this.campaignVendorsLocal.findIndex(item => item.slug === vendorSlug)
    },
    openByNumberFromVendorPanel() {
      this.openedFromVendorPanel = true
      this.openCloseByNumberPanel()
    },
    openCloseByNumberPanel() {
      if (this.visibleByNumberPanel) {
        this.closePanel()
      } else {
        this.visibleByNumberPanel = true
        this.scrollTo()
      }
    },
    openCloseAddVendorPanel() {
      if (this.visibleAddVendorPanel) {
        this.closePanel()
      } else {
        this.visibleAddVendorPanel = true
        this.scrollTo()
      }
    },
    openTrackingNumberEditPanel(vendor) {
      this.visibleEditTrackingNumberPanel = true
      this.editVendor = vendor
      this.scrollTo()
    },
    editAttachedVendorSuccess(editedVendor) {
      const index = this.getVendorIndex(editedVendor.slug)

      if (index + 1) {
        this.campaignVendorsLocal[index].pivot = editedVendor.pivot
      }

      this.campaignVendorsLocal = this.campaignVendorsLocal.slice()
      
      this.closePanel()
    },
    closePanel() {
      this.openedFromVendorPanel = false
      this.visibleAddVendorPanel = false
      this.visibleEditTrackingNumberPanel = false
      this.visibleByNumberPanel = false
      this.scrollTo(false)
    },
    closeByNumbersPanel() {
      if (this.openedFromVendorPanel) {
        this.visibleAddVendorPanel = true
        this.scrollTo()
      } else {
        this.closePanel()
      }
    },
    scrollTo(value = true) {
      if (value) {
        setTimeout(() => {
          this.$vuetify.goTo('#vendor-panel')
        })
      } else {
        this.$vuetify.goTo('#tracking-number')
      }
    },
  },
  watch: {
    visibleAddVendorPanel: {
      handler(value) {
       if (value) {
         this.goToLoadConversionOptions()
         this.goToLoadDuplicatePayoutOptions()
         this.visibleByNumberPanel = false
         this.visibleEditTrackingNumberPanel = false
       }
      }
    },
    visibleByNumberPanel: {
      handler(value) {
       if (value) {
         this.visibleAddVendorPanel = false
         this.visibleEditTrackingNumberPanel = false
       }
      }
    },
    visibleEditTrackingNumberPanel: {
      handler(value) {
       if (value) {
         this.goToLoadConversionOptions()
         this.goToLoadDuplicatePayoutOptions()
         this.visibleAddVendorPanel = false
         this.visibleByNumberPanel = false
       }
      }
    },
    campaignVendors: {
      handler(vendors) {
        this.campaignVendorsLocal = vendors
      }
    },
  },
}
</script>
