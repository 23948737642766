<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="500px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="6" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            Attach to Number
          </div>
        </v-col>
      </v-row>
      
      <v-form ref="form" class="pb-20" v-model="validForm">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-0" cols="12" sm="6">
            <span class="font-normal nameField--text text-sm-right">Select Traffic Source</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="selectPool"
            ></portal-target>
          </v-col>
          
          <v-col cols="12" sm="6">
            <AutocompleteField
              v-model="formData['traffic_source_id']"
              :items="trafficSources"
              item-value="id"
              item-text="name"
              :rules="rules"
              height="48"
            >
              <template #message="{ message }">
                <portal to="selectPool" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </AutocompleteField>
          </v-col>
        </v-row>
      </v-form>
      
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>
        
        <ActionButton class="ml-5 ml-sm-11" :loading="loading" @click="submitForm">Attach</ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import AutocompleteField from '@/components/select/AutocompleteField'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { attachTrafficSourceToNumber } from '@/api/traffic-source-routes'

export default {
  name: 'DialogAttachTrafficSourceToNumber',
  inheritAttrs: false,
  components: { AutocompleteField, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    numberId: {
      type: String | Number,
      required: true
    },
    trafficSources: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    loading: false,
    validForm: true,
    formData: {
      'traffic_source_id': null,
      'number_id': null
    },
    rules: []
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.formData['number_id'] = this.numberId
        
        this.loading = true
        
        const { success, message } = await attachTrafficSourceToNumber(this.formData)
        
        if (success) {
          this.$notify({ type: 'success-bg', text: message ? message : 'Traffic Source was attached!' })
          this.$emit('attachSuccess', this.formData['traffic_source_id'])
          this.closeDialog()
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
        
        this.loading = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    }
  },
  watch: {
    value: {
      handler(value) {
        if (!value) {
          this.rules = []
          this.formData['traffic_source_id'] = null
          this.formData['number_id'] = null
        }
      }
    }
  },
}
</script>
