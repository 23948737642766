export default {
  methods: {
    getNameById(array, id, field = 'name', method = 'find', searchId = 'id') {
      try {
        return method === 'find'
          ? array[method](item => item[searchId] === id)[field]
          : array[method](item => item[searchId] === id)
      } catch (e) {
        return ''
      }
    }
  }
}