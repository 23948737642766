<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    item-key="id"
    class="elevation-0 borderNone font-small tableBg borderTableXS tracking-number-table"
    outlined
    disable-pagination
    hide-default-footer
    show-select
    :custom-sort="customSort"
    :header-props="{ sortIcon: '$sortDownIcon' }"
    :loading="loadingDetailCampaign"
    :loader-height="2"
    :mobile-breakpoint="0"
  >
    <template #header.data-table-select="{ on, props }">
      <PrimaryCheckbox
        v-bind="props"
        v-on="on"
        hide-details
      />
    </template>

    <template #item.data-table-select="{ select, isSelected }">
      <PrimaryCheckbox
        v-model="isSelected"
        @change="select"
        hide-details
      />
    </template>

    <template #item.number="{ item }">
      <div class="d-flex align-center width--full">
        <v-btn
          class="px-0 tableBodyText--text"
          text
          min-width="20"
          active-class="primary"
          @click="openTrackingNumberEditPanel(item)"
        >
          <span class="text-no-wrap primary-text_hover">+{{ item.number }}</span>
        </v-btn>

        <MenuNumberPool class="pl-4" :number="item" v-if="!!item['number_pool']"/>

        <div class="copy-number-container ml-5 d-flex align-center">
          <v-icon class="copy-number-icon" size="14" @click.stop="copyName(item.number)">$copyIcon</v-icon>
        </div>
      </div>
    </template>

    <template #item.name="{ item }">
      <TableInputControl
        v-model="item.name"
        :loadingStatus="loadingStatus"
        @changeValueAction="saveNumberName($event, item.id)"
      />
    </template>

    <template #item.vendor="{ item }">
      {{ item.publisher ? item.publisher.name : '-//-' }}
    </template>

    <template #item.source="{ item }">
      {{ item['traffic_source'] ? item['traffic_source'].name : '-//-' }}
    </template>

    <template #item.payout="{ item }">
      {{ getPayout(item) }}
    </template>

    <template #item.status_id="{ item }">
      <SwitchSm
        :value="getStatus(item)"
        :loading="loadingStatus"
        bgColor="switcherYellow"
        @input="changeStatus(item)"
      />
    </template>

    <template #item.actions="{ item }">
      <TooltipAction bottom message="Edit">
        <template #activator>
          <v-icon class="mr-6" @click="openTrackingNumberEditPanel(item)">$pencilIcon</v-icon>
        </template>
      </TooltipAction>

      <TooltipAction bottom message="Delete">
        <template #activator>
          <v-icon @click="openDetachNumberDialog(item)">$deleteIcon</v-icon>
        </template>
      </TooltipAction>
    </template>

    <template #no-data>
      <div class="d-flex">
        <span class="font-normal text-none text-left" v-if="!campaignNumbers.length">No Numbers linked to this Campaign</span>
        <span class="font-normal text-left" v-else>No results found</span>
      </div>
    </template>

    <template #top>
      <DialogConfirm
        v-model="confirmDialog"
        :loading="loadingAction"
        :title="dialogTitle"
        :subTitle="dialogSubTitle"
        :message="dialogMessage"
        @actionSuccess="actionSuccess"
      />
    </template>
  </v-data-table>
</template>

<script>
import TooltipText from '@/components/tooltip/TooltipText'
import TooltipAction from '@/components/tooltip/TooltipAction'
import SwitchSm from '@/components/controls/SwitchSm'
import PrimaryCheckbox from '@/components/controls/PrimaryCheckbox'
import TableInputControl from '@/components/inputs/TableInputControl'
import MenuTrackingNumberAction from '@/components/menu/MenuTrackingNumberAction'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogAttachNumbersToCampaign from '@/components/dialog/DialogAttachNumbersToCampaign'
import MenuNumberPool from '@/components/menu/MenuNumberPool'

import currency from '@/mixins/currency'
import getNameById from '@/mixins/getNameById'

import { detachNumbersFromCampaign } from '@/api/campaign-routes'
import { updateNumber } from '@/api/number-routes'

import { changeItemsAfterUpdate, copyInStash, customSorting } from '@/helper/app-helper'

export default {
  name: 'TrackingNumbers',
  inheritAttrs: false,
  components: { TooltipText, TooltipAction, SwitchSm, PrimaryCheckbox, TableInputControl, MenuTrackingNumberAction, DialogConfirm, DialogAttachNumbersToCampaign, MenuNumberPool },
  mixins: [ currency, getNameById ],
  props: {
    campaignSlug: {
      type: String | Number,
      required: true
    },
    campaignNumbers: {
      type: Array,
      default: () => []
    },
    campaignVendors: {
      type: Array,
      default: () => []
    },
    selectedNumbers: {
      type: Array,
      default: () => []
    },
    loadingDetailCampaign: {
      type: Boolean,
      required: true
    },
    search: {
      type: String | Number,
      default: ''
    },
  },
  data: () => ({
    loadingAction: false,
    loadingStatus: false,
    pools: [],
    trafficSources: [],
    actionName: null,
    confirmDialog: false,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
  }),
  computed: {
    headers() {
      let headers = []

      const withVendors = this.items.some(n => !!n.pivot)
      const withTrafficSource = this.items.some(n => !!n['traffic_source_id'])

      if (withVendors || withTrafficSource) {
        headers = [
          { text: 'NUMBER', value: 'number', class: 'text-no-wrap' },
          { text: 'NAME', value: 'name', class: 'text-no-wrap' },
        ]
      } else {
        headers = [
          { text: 'NUMBER', value: 'number', class: 'text-no-wrap', width: 300 },
          { text: 'NAME', value: 'name', class: 'text-no-wrap', width: 200 },
        ]
      }

      if (withVendors) {
        headers.push(
          { text: 'VENDOR', value: 'vendor' },
          { text: 'PAYOUT', value: 'payout', align: 'center' },
        )
      }

      if (withTrafficSource) {
        headers.push(
          { text: 'TRAFFIC SOURCE', value: 'source', class: 'text-no-wrap' },
        )
      }

      headers.push(
        { text: 'STATUS', value: 'status_id', align: 'center' },
        { text: 'ACTIONS', value: 'actions', sortable: false, align: 'right', width: 130 },
      )

      return headers
    },
    items() {
      return this.searchItems(this.campaignNumbers, this.search)
    },
    selected: {
      get() {
        return this.selectedNumbers
      },
      set(selected) {
        this.$emit('update:selectedNumbers', selected)
      }
    },
  },
  methods: {
    searchItems(items, search) {
      this.selected = []

      if (!search) return items
      search = search.toString().toLowerCase()
      if (search.trim() === '') return items

      return items.filter(item => {
        if (!!item.name && item.name.toString().toLowerCase().indexOf(search) !== -1) return true

        if (!!item.publisher && item.publisher.name.toString().toLowerCase().indexOf(search) !== -1) return true

        if (!!item['traffic_source'] && item['traffic_source'].name.toString().toLowerCase().indexOf(search) !== -1) return true

        let prev = search[0] === '+' ? '+' : ''

        if (`${prev}${item.number.toString().toLowerCase()}`.indexOf(search) !== -1) return true

        prev = search[0] === '$' ? '' : '$'

        return !!item.pivot && `${this.currency(item.pivot.payout)}`.indexOf(prev + search) !== -1
      })
    },
    getStatus(item) {
      return item['status_id'] === 1
    },
    getPayout(item) {
      if (item.pivot) {
        const currentVendor = this.campaignVendors.find(v => v.id === item['publisher_id'])

        if (currentVendor) {
          return this.currency(currentVendor.pivot.payout)
        }
      }

      return '-//-'
    },
    openTrackingNumberEditPanel(number) {
      this.$emit('openEditNumber', number)
    },
    openDetachNumberDialog(trackingNumber) {
      this.actionName = 'goToDetachNumbersFromCampaign'
      this.selectedItem = trackingNumber
      this.dialogTitle = 'Detach number'
      this.dialogSubTitle = `+${trackingNumber.number}`
      this.dialogMessage = 'Are you sure you want to detach this number?'
      this.confirmDialog = true
    },
    async goToDetachNumbersFromCampaign() {
      const { success, message } = await detachNumbersFromCampaign({
        campaignId: this.campaignSlug,
        params: { numbers: [this.selectedItem.id] }
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('update:campaignNumbers', this.campaignNumbers.filter(item => item.id !== this.selectedItem.id))
        this.$emit('reloadCampaignManage')
        this.$emit('closePanel')
        this.confirmDialog = false
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    changeStatus(number) {
      this.updateNumber({
        numberId: number.id,
        formData: { 'toggle_status': true },
      })
    },
    saveNumberName(name, id) {
      this.updateNumber({
        numberId: id,
        formData: { name },
      })
    },
    async updateNumber({ numberId, formData }) {
      this.loadingStatus = true

      const { success, payload, message } = await updateNumber({ numberId, formData })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })

        this.$emit('update:campaignNumbers', changeItemsAfterUpdate(this.campaignNumbers, payload, 'id'))
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingStatus = false
    },
    actionSuccess() {
      this[this.actionName]()
    },
    detachNumberSuccess({ vendorSlug, numberId }) {
      const index = this.getVendorIndex(vendorSlug)

      if (index + 1) {
        this.items[index].numbers = this.items[index].numbers.filter(number => number.id !== numberId)

        this.updateItems()
      }

      this.$emit('reloadCampaignManage')
    },
    attachPoolToNumberSuccess({ vendorSlug, numberId, poolId }) {
      const index = this.getVendorIndex(vendorSlug)

      if (index + 1) {
        this.items[index].numbers = this.items[index].numbers.map(number => {
          if (number.id === numberId) {
            number['number_pool_id'] = poolId
          }
          return number
        })

        this.updateItems()
      }
    },
    detachPoolFromNumberSuccess({ vendorSlug, numberId }) {
      const index = this.getVendorIndex(vendorSlug)

      if (index + 1) {
        this.items[index].numbers = this.items[index].numbers.map(number => {
          if (number.id === numberId) {
            number['number_pool_id'] = null
          }
          return number
        })

        this.updateItems()
      }
    },
    attachTrafficSourceToNumberSuccess({ vendorSlug, numberId, trafficSourceId }) {
      const index = this.getVendorIndex(vendorSlug)

      if (index + 1) {
        this.items[index].numbers = this.items[index].numbers.map(number => {
          if (number.id === numberId) {
            number['traffic_source_id'] = trafficSourceId
          }
          return number
        })

        this.updateItems()
      }
    },
    detachTrafficSourceSuccess({ vendorSlug, numberId }) {
      const index = this.getVendorIndex(vendorSlug)

      if (index + 1) {
        this.items[index].numbers = this.items[index].numbers.map(number => {
          if (number.id === numberId) {
            number['traffic_source_id'] = null
          }
          return number
        })

        this.updateItems()
      }
    },
    getVendorIndex(vendorSlug) {
      return this.items.findIndex(item => item.slug === vendorSlug)
    },
    updateItems() {
      this.$emit('update:campaignNumbers', this.campaignNumbers)
    },
    customSort(items, index, isDesc) {
      const fieldName = index[0]
      const type = this.getFieldType(fieldName)

      items.sort((a, b) => {
        let f = ''
        let s = ''

        switch (fieldName) {
          case 'payout':
            f = !!a['pivot'] ? +a['pivot']['payout'] : -1
            s = !!b['pivot'] ? +b['pivot']['payout'] : -1
            break
          case 'source':
            f = !!a['number_pool'] ? a['number_pool'].name : ''
            s = !!b['number_pool'] ? b['number_pool'].name : ''
            break
          case 'vendor':
            f = !!a['publisher'] ? a['publisher'].name : ''
            s = !!b['publisher'] ? b['publisher'].name : ''
            break
          default:
            f = !!a[fieldName] ? a[fieldName] : ''
            s = !!b[fieldName] ? b[fieldName] : ''
        }

        if(typeof f !== 'undefined') {
          return customSorting(f, s, !!isDesc[0], type)
        }
      })
      return items
    },
    getFieldType(name) {
      if (name === 'payout' || name === 'number') {
        return 'number'
      }

      if (name === 'status_id') {
        return 'status'
      }

      return 'string'
    },
    copyName(name) {
      copyInStash(name)
      this.$notify({ type: 'info-bg', text: 'Copy successful' })
    },
  },
  watch: {
    loadingStatus: {
      handler(value) {
        if (!value) {
          this.$emit('changeStatusSuccess')
        }
      }
    },
  },
}
</script>