<template>
  <v-card v-bind="$attrs" flat outlined>
    <v-row class="search-content d-block d-md-flex align-center" no-gutters>
      <v-col cols="12" md="3" lg="2">
        <TextDescription :description="description" />
      </v-col>
      
      <v-col cols="12" md="9" lg="10">
        <v-form ref="form" v-model="validateForm">
          <v-row no-gutters>
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Country</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="countryName"
              ></portal-target>
            </v-col>
            
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <FormField
                v-if="countryId"
                :value="getCountryNameById(countryId)"
                class="field-container"
                readonly
              />
              <AutocompleteField
                v-else
                v-model="data.country"
                class="field-container"
                :items="countries"
                item-text="name"
                item-value="id"
                return-object
                :rules="rules"
                @change="changePrefix"
                checkBg
              >
                <template #message="{ key, message }">
                  <portal to="countryName" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </AutocompleteField>
            </v-col>
  
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Prefix</span>
            </v-col>
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <FormField
                v-model="data.prefix"
                class="field-container"
                :prefix="numCode"
                @keypress="onlyNumbers"
              />
            </v-col>
  
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Type</span>
            </v-col>
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <ToggleButton
                v-model="data['options_type']"
                :buttons="optionsType"
                buttonTypeString
                item-text="name"
                item-value="id"
              />
            </v-col>
  
            <v-col class="pt-5 pb-15" cols="12" sm="8" md="9" offset-sm="4" offset-md="3">
              <CancelButton v-if="isExpandPanel" class="mr-10" @click="closePanel">
                Cancel
              </CancelButton>
              
              <ActionButton :loading="loading" @click="search">
                Search
              </ActionButton>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    
    <v-row id="search-number-table" class="numbers-table-content" no-gutters>
      <v-col cols="12" md="9" lg="10" offset-md="3" offset-lg="2">
        <v-row no-gutters>
          <v-col cols="12" lg="9" offset-lg="3">
            <BuyNumber
              v-model="numberTable"
              class="table-container"
              :items="numberAll"
              :loadingDetailNumber="loading"
              @openDialogConfirm="openDialogConfirm"
            />
            
            <v-pagination
              class="paginationBody text-center pt-6"
              color="paginationBg"
              v-if="dataPagination.lastPage > 1"
              :value="dataPagination.page"
              :length="dataPagination.lastPage"
              :page="dataPagination.page"
              total-visible="8"
              :disabled="loading"
              prev-icon="$prevIcon"
              next-icon="$nextIcon"
              @input="changePage"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
    <DialogConfirm
      v-model="confirmDialog"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      :loading="loadingAction"
      @actionSuccess="goToBuyNumber"
    />
  </v-card>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import ToggleButton from '@/components/buttons/ToggleButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import AutocompleteField from '@/components/select/AutocompleteField'
import BuyNumber from '@/components/tables/BuyNumber'
import DialogConfirm from '@/components/dialog/DialogConfirm'

import { getSearchNumbers, buyNumber, getSearchNumbersForVendor, buyNumberForVendor } from '@/api/number-routes'

import { mapState, mapGetters } from 'vuex'
import { Types as indexTypes } from '@/store'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'CardBuyNumbers',
  inheritAttrs: false,
  components: { TextDescription, FormField, ToggleButton, CancelButton, ActionButton, AutocompleteField, BuyNumber, DialogConfirm },
  props: {
    description: {
      type: Object,
      required: true
    },
    isExpandPanel: {
      type: Boolean,
      default: false
    },
    countryId: {
      type: Number | String,
      default: null
    },
    campaignSlug: {
      type: Number | String,
      default: null
    },
  },
  data: () => ({
    loading: false,
    loadingAction: false,
    loadingVendors: false,
    validateForm: true,
    currentCountry: null,
    numberAll: [],
    rules: [],
    optionsType: [
      { name: 'Local', id: 'local' },
      { name: 'Toll-Free', id: 'tollfree' },
      { name: 'Any', id: 'any' },
    ],
    numCode: '',
    formData: null,
    data: {
      name: null,
      country: null,
      'status_id': 1,
      'prefix': '',
      'options_type': 'local',
    },
    dataPagination: {
      page: 1,
      lastPage: 1,
    },
    numberTable: true,
    confirmDialog: false,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    dialogMethod: null,
    dialogUrl: null,
    paramsForBuyNumber: null,
  }),
  computed: {
    ...mapState({
      countries: state => state.countries,
    }),
    ...mapGetters({
      isManager: authTypes.getters.IS_MANAGER,
      getCountryById: indexTypes.getters.GET_COUNTRY_BY_ID,
      getCountryNameById: indexTypes.getters.GET_COUNTRY_NAME_BY_ID,
    }),
  },
  mounted() {
    if (this.countryId) {
      this.data.country = this.getCountryById(this.countryId)
      this.numCode = this.data.country['num_code']
    }
  },
  methods: {
    search() {
      this.formData = null
      this.dataPagination.page = 1
      this.searchNumbers()
    },
    async searchNumbers() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true
        this.formData = this.formData
          ? {
            ...this.formData,
            page: this.dataPagination.page,
          }
          : {
            'country_iso': this.data.country.code,
            'country_id': this.data.country.id,
            pattern: this.data.prefix,
            type: this.data['options_type'],
            page: this.dataPagination.page,
          }
        this.currentCountry = this.data.country
        
        const { success, payload, message } = this.isManager
          ? await getSearchNumbers(this.formData)
          : await getSearchNumbersForVendor(this.formData)
        
        if (success && payload.numbers.length) {
          this.$vuetify.goTo('#search-number-table', { offset: 20 })
          this.numberAll = payload.numbers
          
          if (!!payload.meta?.limit) {
            this.dataPagination = {
              page: Math.floor(payload.meta.offset / 20) + 1,
              lastPage: Math.ceil(payload.meta['total_count'] / payload.meta.limit)
            }
          } else {
            this.dataPagination.page = 1
            this.dataPagination.lastPage = 1
          }
          this.rules = []
        } else {
          if (message) {
            this.$notify({ type: 'error-bg', duration: 15000, text: message })
          } else if (!payload.numbers.length) {
            this.$notify({ type: 'info-bg', text: 'No phone numbers found for these parameters' })
          }
          this.numberAll = []
          this.dataPagination = {
            page: 1,
            lastPage: 1
          }
        }
        
        this.loading = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    changePrefix(event) {
      if (event) {
        this.numCode = event['num_code']
      }
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.searchNumbers()
      }
      this.$vuetify.goTo('#search-number-table', { offset: 20 })
    },
    openDialogConfirm(number) {
      this.dialogTitle = 'Buy number'
      this.dialogSubTitle = `${number.number}`
      this.dialogMessage = 'Are you sure you want to buy a phone number?'
      this.paramsForBuyNumber = {
        'country_id': this.currentCountry.id,
        number
      }
      this.confirmDialog = true
    },
    async goToBuyNumber() {
      this.loadingAction = true
      
      const { success, payload, message } = this.isManager
        ? await buyNumber(this.paramsForBuyNumber)
        : await buyNumberForVendor(this.paramsForBuyNumber)
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message ? message : 'Number was bought' })
        this.$emit('reloadCampaignManage')
      } else {
        message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.confirmDialog = false
      this.loadingAction = false
    },
    onlyNumbers(event) {
      const regex = new RegExp('^[0-9]+$')
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    closePanel() {
      this.$emit('closePanelByNumbers')
    },
  },
}
</script>
