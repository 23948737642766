<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="700"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg js-code-card px-sm-17 px-md-35 py-14 py-sm-16 py-md-33">
      <div class="font-large-title text-uppercase dialogShowCodeTitle--text px-11 px-sm-0 pb-4">
        Install Call Tracking Tag
      </div>
      <div class="font-normal dialogShowCodeSubTitle--text px-11 px-sm-0">
        To enable Dialics call tracking you need to place some code snippets on your website. This code makes all of the magic happen. Once placed you will be able to see everything you need about your campaigns to optimize and increase your profit.
      </div>
      
      <div class="d-flex flex-wrap justify-end px-11 px-sm-0 pb-10 pb-sm-20 pt-sm-6">
        <div class="alarm-container mt-10 mx-auto">
          <span class="alarm-text text-no-wrap">
            <span class="font-large-alarm primary--text pr-10">!</span>
            <span class="font-normal dialogShowCodeColor--text">Please don't modify this code</span>
          </span>
        </div>
        
        <v-spacer class="d-none d-sm-block" />
        
        <div class="d-flex justify-end pt-10">
          <CancelButton @click="closeDialog">Close</CancelButton>
  
          <CopyButton
            class="ml-5 ml-sm-11"
            @click="copyJsCode"
          >
            Copy code
          </CopyButton>
        </div>
      </div>
      
      <FormTextarea
        ref="jsCode"
        class="rounded-0 borderNone"
        backGround="dialogShowCodeBg"
        tile
        :value="code"
        :loading="loading"
        auto-grow
        readonly
      />
    </v-card>
  </v-dialog>
</template>

<script>
import FormTextarea from '@/components/inputs/FormTextarea'
import CopyButton from '@/components/buttons/CopyButton'
import CancelButton from '@/components/buttons/CancelButton'

import { getJSCode } from '@/api/pool-routes'

export default {
  name: 'DialogShowJSCode',
  inheritAttrs: false,
  components: { FormTextarea, CopyButton, CancelButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    numberId: {
      type: String | Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    code: ''
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    async goToLoadJSCode() {
      this.loading = true
      
      const { success, payload, message } = await getJSCode({ 'number_id': this.numberId })
      
      if (success) {
        this.code = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    copyJsCode() {
      this.$refs['jsCode'].$refs['textareaRef'].$refs.input.select()
      document.execCommand('copy')
    },
    closeDialog() {
      this.show = false
    }
  },
  watch: {
    show: {
      handler(value) {
        if (value) {
          this.goToLoadJSCode()
        } else {
          this.code = ''
        }
      }
    }
  },
}
</script>

<style lang="scss">
.js-code-card {
  .alarm-container {
    border: 1px solid var(--v-dialogShowCodeAlarmBorder-base);
    border-radius: 5px;
    padding: 11px 22px;
  }
  
  .v-input {
    textarea {
      color: var(--v-dialogShowCodeColor-base);
    }
  }
  
}
</style>