<template>
  <v-row
    class="d-flex align-center pa-12 py-md-20"
    :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
    no-gutters
  >
    <v-col cols="12" md="3" lg="2">
      <TextDescription :description="currentDescription" />
    </v-col>
    <v-col cols="12" md="9" lg="10">
      <v-row no-gutters v-if="isCreate">
        <v-col
          class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
          cols="12" sm="4" md="3"
        >
          <span class="font-normal nameField--text text-sm-right">Choose an Option</span>
        </v-col>
        <v-col class="pb-10" cols="12" sm="8" md="9">
          <ToggleButton
            v-model="currentTrackingPixelOption"
            :buttons="trackingPixelOptions"
          />
        </v-col>
      </v-row>
      
      <v-form ref="formPixel" v-model="validateForm">
        <v-slide-y-transition hide-on-leave>
          <v-row no-gutters v-if="!currentTrackingPixelOption">
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Select Pixel</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="selectPixel"
              ></portal-target>
            </v-col>
            
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <AutocompleteField
                class="field-container"
                v-model="pixelLocal"
                :items="freePixels"
                item-text="name"
                item-value="id"
                :rules="rules"
                checkBg
              >
                <template #message="{ key, message }">
                  <portal to="selectPixel" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </AutocompleteField>
            </v-col>
          </v-row>
        </v-slide-y-transition>
        
        <v-slide-y-transition hide-on-leave>
          <v-row no-gutters v-if="currentTrackingPixelOption">
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Pixel Name</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="pixelName"
              ></portal-target>
            </v-col>
            
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <FormField
                v-model="formDataPixel.name"
                :rules="rules"
                class="field-container"
              >
                <template #message="{ key, message }">
                  <portal to="pixelName" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>
            
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Event Name</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="eventName"
              ></portal-target>
            </v-col>
            
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <SelectField
                v-model="formDataPixel['event_name']"
                class="field-container"
                :items="events"
                :height="46"
                item-text="name"
                item-value="name"
                :rules="rules"
                :loading="loadingEvents"
                checkBg
              >
                <template #message="{ key, message }">
                  <portal to="eventName" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </SelectField>
            </v-col>
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">URL</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="urlString"
              ></portal-target>
            </v-col>
            
            <v-col class="pb-4 d-sm-none" cols="12">
              <portal-target name="tagField"></portal-target>
            </v-col>
            
            <v-col class="pb-10 d-flex" cols="12" sm="8" md="9">
              <FormTextarea
                ref="urlStringField"
                v-model.trim="formDataPixel.url"
                :rules="urlRules"
                class="field-container-url mr-sm-11"
                rows="1"
                auto-grow
                @keyup="openTokenMenu"
              >
                <template #message="{ key, message }">
                  <portal to="tagField" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormTextarea>
              <portal to="tagField" :disabled="!checkSm">
                <v-menu
                  v-model="tokenMenu"
                  :close-on-content-click="false"
                  nudge-width="200"
                  nudge-right="10"
                  max-height="400"
                  top
                  offset-x
                >
                  <template #activator="{ on }">
                    <ActionButton v-on="on">
                      Tags
                    </ActionButton>
                  </template>
                  <v-list class="cardBg scroll-list" dense>
                    <v-list-item v-if="!Object.keys(pixelParams).length">
                      <v-list-item-content>
                        <v-list-item-title>No parameters</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-group
                      v-else
                      v-for="name in Object.keys(pixelParams)"
                      :key="name"
                    >
                      <template #activator>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="font-normal">
                              {{ name }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-list-item
                        v-for="(param, index) in pixelParams[name]"
                        :key="name + index"
                        @click="changeParam(param)"
                      >
                        <v-list-item-content class="pl-18">
                          <v-list-item-title class="font-normal">
                            {{ param.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-menu>
              </portal>
            </v-col>
          </v-row>
        </v-slide-y-transition>
        
        <v-row no-gutters>
          <v-col class="pt-5 pt-md-10" cols="12" sm="8" md="9" offset-sm="4" offset-md="3">
            <CancelButton
              class="mr-5 mr-sm-10"
              @click="closePanel"
            >
              Cancel
            </CancelButton>
            
            <ActionButton :loading="loading" @click="submitForm">
              Save and Continue
            </ActionButton>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ToggleButton from '@/components/buttons/ToggleButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import AutocompleteField from '@/components/select/AutocompleteField'
import SelectField from '@/components/select/SelectField'
import FormField from '@/components/inputs/FormField'
import FormTextarea from '@/components/inputs/FormTextarea'

import { getPixelEvents, getAllTreeUrlParams } from '@/api/integration-routes'
import { attachPixelToCampaign } from '@/api/campaign-routes'
import { createPixel, updatePixel } from '@/api/integration-routes'

export default {
  name: 'TrackingPixelPanel',
  inheritAttrs: false,
  components: { TextDescription, ToggleButton, CancelButton, ActionButton, AutocompleteField, SelectField, FormField, FormTextarea },
  props: {
    description: {
      type: Object,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    freePixels: {
      type: Array,
      required: true
    },
    changedPixel: {
      required: true,
    },
  },
  data: () => ({
    loading: false,
    loadingEvents: false,
    isCreate: true,
    validateForm: true,
    pixelLocal: null,
    newPixelId: null,
    currentTrackingPixelOption: 0,
    trackingPixelOptions: [
      { name: 'Select existing', id: 0 },
      { name: 'Create new', id: 1 }
    ],
    events: [],
    pixelParams: [],
    menu: false,
    tokenMenu: false,
    formDataPixel: {
      name: null,
      'event_name': null,
      url: null,
    },
    rules: [],
    urlRules: []
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    currentDescription() {
      return this.isCreate
        ? !!this.currentTrackingPixelOption
          ? this.description.createPixel
          : this.description.addPixel
        : this.description.editPixel
    },
  },
  mounted() {
    this.goToLoadPixelEvents()
    this.goToLoadPixelParams()
  },
  methods: {
    async goToLoadPixelEvents() {
      this.loadingEvents = true
      
      const { success, payload, message } = await getPixelEvents()
      
      if (success) {
        this.events = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingEvents = false
    },
    async goToLoadPixelParams() {
      const { success, payload, message } = await getAllTreeUrlParams()
      
      if (success) {
        this.pixelParams = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    parseProps(pixel) {
      Object.keys(this.formDataPixel).forEach(name => {
        this.formDataPixel[name] = pixel[name]
      })
      this.currentTrackingPixelOption = 1
      this.isCreate = false
    },
    async submitForm() {
      await this.validationForm()

      if (this.$refs.formPixel.validate()) {
        if (!!this.changedPixel) {
          this.goToUpdatePixel()
        } else {
          if (!!this.currentTrackingPixelOption) {
            this.goToCreatePixel()
          } else {
            this.goToAttachPixelToCampaign()
          }
        }
      }
    },
    async goToUpdatePixel() {
      this.loading = true

      const { success, payload, message } = await updatePixel({
        pixelId: this.changedPixel.id,
        formData: this.formDataPixel,
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('updateSuccess', payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    async goToCreatePixel() {
      this.loading = true

      const { success, payload, message } = await createPixel(this.formDataPixel)

      if (success) {
        this.$emit('createSuccess', payload)
        this.$notify({ type: 'success-bg', text: message })
        this.pixelLocal = payload.id
        this.currentTrackingPixelOption = 0
        this.clearFields()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    async goToAttachPixelToCampaign() {
      this.loading = true

      const { success, payload, message } = await attachPixelToCampaign({
        campaignId: this.campaignSlug,
        formData: {
          'pixel_id': this.pixelLocal
        }
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('attachSuccess', payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    validationForm() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
      this.urlRules = [
        v => !!v || 'This field is required',
        v => this.checkUrl(v) || 'URL must be valid'
      ]
    },
    checkUrl(url) {
      return /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(url)
    },
    openTokenMenu(event) {
      if (event.keyCode === 187 && this.checkUrl(this.formDataPixel.url)) {
        this.tokenMenu = true
      }
    },
    changeParam(param) {
      if (param && param.code && this.checkUrl(this.formDataPixel.url)) {
        this.formDataPixel.url = this.formDataPixel.url + param.code
        this.tokenMenu = false
        this.$refs['urlStringField'].$children[0].$children[0].$refs.input.focus()
      }
    },
    closePanel() {
      this.$emit('closePanel')
    },
    clearFields() {
      this.formDataPixel = {
        name: null,
        'event_name': null,
        url: null,
      }
      this.rules = []
      this.urlRules = []
    },
  },
  watch: {
    changedPixel: {
      immediate: true,
      handler(pixel) {
        if (!!pixel) {
          this.parseProps(pixel)
        } else {
          this.currentTrackingPixelOption = 0
          this.clearFields()
          this.isCreate = true
        }
      }
    },
  },
}
</script>
