<template>
  <v-row
    class="d-flex align-center pa-12 py-md-20"
    :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
    no-gutters
  >
    <v-col cols="12" md="3" lg="2">
      <TextDescription :description="currentDescription" />
    </v-col>
    <v-col cols="12" md="9" lg="10">
      <v-row no-gutters v-if="isCreate">
        <v-col
          class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
          cols="12" sm="4" md="3"
        >
          <span class="font-normal nameField--text text-sm-right">Choose an Option</span>
        </v-col>
        <v-col class="pb-10" cols="12" sm="8" md="9">
          <ToggleButton
            v-model="currentUrlParamsOption"
            :buttons="urlParamsOptions"
          />
        </v-col>
      </v-row>
      
      <v-form ref="formUrlParams" v-model="validateForm">
        <v-slide-y-transition hide-on-leave>
          <v-row no-gutters v-if="!currentUrlParamsOption">
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Select Url Params</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="selectUrlParams"
              ></portal-target>
            </v-col>
            
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <AutocompleteField
                class="field-container"
                v-model="localUrlParams"
                :items="freeUrlParams"
                item-text="name"
                item-value="id"
                :rules="rules"
                checkBg
              >
                <template #message="{ key, message }">
                  <portal to="selectUrlParams" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </AutocompleteField>
            </v-col>
          </v-row>
        </v-slide-y-transition>
        
        <v-slide-y-transition hide-on-leave>
          <v-row no-gutters v-if="currentUrlParamsOption">
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Param Name</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="campaignUrlParamsName"
              ></portal-target>
            </v-col>
            
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <FormField
                v-model="formDataUrlParams.name"
                :rules="rules"
                class="field-container"
              >
                <template #message="{ key, message }">
                  <portal to="campaignUrlParamsName" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>
            
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Group param</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="campaignUrlParamsGroupParam"
              ></portal-target>
            </v-col>
            
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <FormField
                v-model="formDataUrlParams['group_param']"
                :rules="rules"
                class="field-container"
              >
                <template #message="{ key, message }">
                  <portal to="campaignUrlParamsGroupParam" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">URL name</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="campaignUrlParamsUrlName"
              ></portal-target>
            </v-col>
            
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <FormField
                v-model="formDataUrlParams['url_param']"
                :rules="rules"
                class="field-container"
              >
                <template #message="{ key, message }">
                  <portal to="campaignUrlParamsUrlName" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>
          </v-row>
        </v-slide-y-transition>
        
        <v-row no-gutters>
          <v-col class="pt-5 pt-md-10" cols="12" sm="8" md="9" offset-sm="4" offset-md="3">
            <CancelButton
              class="mr-5 mr-sm-10"
              @click="closePanel"
            >
              Cancel
            </CancelButton>
            
            <ActionButton :loading="loading" @click="submitForm">
              Save and Continue
            </ActionButton>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ToggleButton from '@/components/buttons/ToggleButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import AutocompleteField from '@/components/select/AutocompleteField'
import FormField from '@/components/inputs/FormField'

import { attachUrlParamsToCampaign } from '@/api/campaign-routes'
import { createUrlParams, updateUrlParams } from '@/api/integration-routes'

export default {
  name: 'UrlParamsPanel',
  inheritAttrs: false,
  components: { TextDescription, ToggleButton, CancelButton, ActionButton, AutocompleteField, FormField },
  props: {
    description: {
      type: Object,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    freeUrlParams: {
      type: Array,
      required: true
    },
    changedUrlParams: {
      required: true,
    },
  },
  data: () => ({
    loading: false,
    isCreate: true,
    validateForm: true,
    localUrlParams: null,
    currentUrlParamsOption: 0,
    urlParamsOptions: [
      { name: 'Select existing', id: 0 },
      { name: 'Create new', id: 1 },
    ],
    formDataUrlParams: {
      name: null,
      group_param: null,
      url_param: null,
    },
    rules: [],
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    currentDescription() {
      return this.isCreate
        ? !!this.currentUrlParamsOption
          ? this.description.createUrlParams
          : this.description.addUrlParams
        : this.description.editUrlParams
    },
  },
  methods: {
    parseProps(urlParams) {
      Object.keys(this.formDataUrlParams).forEach(name => {
        this.formDataUrlParams[name] = urlParams[name]
      })
      this.currentUrlParamsOption = 1
      this.isCreate = false
    },
    async submitForm() {
      await this.validationForm()
      
      if (this.$refs.formUrlParams.validate()) {
        if (!!this.changedUrlParams) {
          this.goToUpdateUrlParams()
        } else {
          if (!!this.currentUrlParamsOption) {
            this.goToCreateUrlParams()
          } else {
            this.goToAttachUrlParamsToCampaign()
          }
        }
      }
    },
    async goToUpdateUrlParams() {
      this.loading = true
      
      const { success, payload, message } = await updateUrlParams({
        urlParamsId: this.changedUrlParams.id,
        formData: this.formDataUrlParams,
      })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('updateSuccess', payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    async goToCreateUrlParams() {
      this.loading = true
      
      const { success, payload, message } = await createUrlParams(this.formDataUrlParams)
      
      if (success) {
        this.$emit('createSuccess', payload)
        this.$notify({ type: 'success-bg', text: message })
        this.localUrlParams = payload.id
        this.currentUrlParamsOption = 0
        this.clearFields()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    async goToAttachUrlParamsToCampaign() {
      this.loading = true
      
      const { success, payload, message } = await attachUrlParamsToCampaign({
        campaignId: this.campaignSlug,
        formData: {
          'param_id': this.localUrlParams
        }
      })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('attachSuccess', payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    closePanel() {
      this.$emit('closePanel')
    },
    clearFields() {
      this.formDataUrlParams = {
        name: null,
        group_param: null,
        url_param: null,
      }
      this.rules = []
    },
  },
  watch: {
    changedUrlParams: {
      immediate: true,
      handler(urlParams) {
        if (!!urlParams) {
          this.parseProps(urlParams)
        } else {
          this.currentUrlParamsOption = 0
          this.clearFields()
          this.isCreate = true
        }
      }
    },
  },
}
</script>
