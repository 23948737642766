<template>
  <v-row
    class="d-flex align-center pa-12 py-md-20"
    :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
    no-gutters
  >
    <v-col cols="12" md="3" lg="2">
      <TextDescription :description="description" />
    </v-col>
    <v-col cols="12" md="9" lg="10">
      <v-form ref="attachVendor" v-model="validateForm">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Choose an Option</span>
          </v-col>
          <v-col class="d-flex flex-wrap" cols="12" sm="8" md="9">
            <div class="pr-11 pb-5">
              <ToggleButton
                v-model="currentVendorOptions"
                :buttons="vendorOptions"
              />
            </div>
            
            <div class="pb-10">
              <ActionOutlinedButton
                class="borderColorCustom"
                color="outlinedPrimaryButtonColor"
                @click="openVendorDialog"
                height="46"
              >
                New Vendor
              </ActionOutlinedButton>
            </div>
          </v-col>
          
          <v-expand-transition>
            <v-col cols="12" v-if="!!currentVendorOptions">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Select Vendor</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="selectVendor"
                  ></portal-target>
                </v-col>
                
                <v-col class="pb-10" cols="12" sm="8" md="9">
                  <AutocompleteField
                    class="field-container"
                    v-model="formDataAddVendor.publisher"
                    :items="freeVendorsNotYou"
                    item-text="name"
                    item-value="slug"
                    @change="searchNumbers"
                    :rules="rules"
                    check-bg
                  >
                    <template #message="{ key, message }">
                      <portal to="selectVendor" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </AutocompleteField>
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Add number</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="addNumber"
            ></portal-target>
          </v-col>
          
          <v-col class="d-flex flex-wrap" cols="12" sm="8" md="9">
            <AutocompleteField
              class="field-container mr-sp-11 pb-5"
              v-model="changedNumbers"
              :items="freeNumbers"
              item-text="number"
              item-value="id"
              multiple
              return-object
              checkBg
              :rules="rulesArray"
              no-data-text="Select a Vendor"
            >
              <template #message="{ key, message }">
                <portal to="addNumber" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
  
              <template #selection="{ item, index }">
<!--                <div class="pr-4" v-if="index < 2">-->
                  <span class="nameField--text pr-4" v-if="index === 0">
                    {{ getCntNumbers }}
                  </span>
<!--                  <span-->
<!--                    v-if="index === 1"-->
<!--                    class="nameField&#45;&#45;text font-xs-mobile"-->
<!--                  >+{{ changedNumbers.length - 1 }} others</span>-->
<!--                </div>-->
              </template>
            </AutocompleteField>
            
            <div class="pb-10">
              <ActionOutlinedButton
                @click="openByNumberPanel"
                height="46"
              >
                Buy New Number
              </ActionOutlinedButton>
            </div>
          </v-col>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Payout, $</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="payout"
            ></portal-target>
          </v-col>
          
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <InputNumberControl
              v-model="formDataAddVendor.payout"
              :rules="rules"
              :min="0"
              :step="0.01"
            >
              <template #message="{ key, message }">
                <portal to="payout" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </InputNumberControl>
          </v-col>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Payout on</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <ToggleButton
              v-model="formDataAddVendor['convert_on_id']"
              :buttons="conversionOptions"
              :class="{'flex-column': checkToggleButtonWidth}"
            />
          </v-col>
          
          <v-expand-transition>
            <v-col cols="12" v-if="formDataAddVendor['convert_on_id'] === 3">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Length</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="length"
                  ></portal-target>
                </v-col>
                
                <v-col class="pb-10 d-flex align-center" cols="12" sm="8" md="9">
                  <InputNumberControl
                    v-model="optionalData.length"
                    :rules="rules"
                  >
                    <template #message="{ key, message }">
                      <portal to="length" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </InputNumberControl>
                  <div class="pl-4">
                    <span class="font-normal nameField--text">seconds</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Duplicate Payouts</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <ToggleButton
              v-model="formDataAddVendor['duplicate_id']"
              :buttons="duplicatePayoutOptions"
            />
          </v-col>
          
          <v-expand-transition>
            <v-col cols="12" v-if="formDataAddVendor['duplicate_id'] === 3">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Days</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="days"
                  ></portal-target>
                </v-col>
                
                <v-col class="pb-10" cols="12" sm="8" md="9">
                  <InputNumberControl
                    v-model="optionalDataDuplicate['time_limit']"
                    class="field-container"
                    :rules="rules"
                  >
                    <template #message="{ key, message }">
                      <portal to="days" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </InputNumberControl>
                </v-col>
              
              </v-row>
            </v-col>
          </v-expand-transition>
          
          <v-col class="pt-5 pt-md-10" cols="12" sm="8" md="9" offset-sm="4" offset-md="3">
            <CancelButton
              class="mr-5 mr-sm-10"
              @click="closePanel"
            >
              Cancel
            </CancelButton>
            
            <ActionButton
              :loading="loading"
              @click="submitForm"
            >
              Save and Continue
            </ActionButton>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    
    <DialogVendor
      v-model="dialogVendor"
      @createSuccess="createVendorSuccess"
    />
  </v-row>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ToggleButton from '@/components/buttons/ToggleButton'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import AutocompleteField from '@/components/select/AutocompleteField'
import InputNumberControl from '@/components/inputs/InputNumberControl'
import DialogVendor from '@/components/dialog/DialogVendor'

import { attachVendorToCampaign, attachNumberToCampaign } from '@/api/campaign-routes'
import { attachNumbersToVendor } from '@/api/vendor-routes'

export default {
  name: 'CampaignAttachVendorPanel',
  inheritAttrs: false,
  components: { TextDescription, ToggleButton, ActionOutlinedButton, CancelButton, ActionButton, AutocompleteField, InputNumberControl, DialogVendor },
  props: {
    vendor: {
      type: Object,
      default: null
    },
    description: {
      type: Object,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      required: true
    },
    conversionOptions: {
      type: Array,
      required: true
    },
    duplicatePayoutOptions: {
      type: Array,
      required: true
    },
    freeVendors: {
      type: Array,
      required: true
    },
    freeNumbersAll: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    validateForm: true,
    loading: false,
    dialogVendor: false,
    currentVendorOptions: 0,
    formDataAddVendor: {
      publisher: null,
      payout: 0,
      'convert_on_id': 1,
      'duplicate_id': 1,
    },
    optionalData: {
      length: 10,
    },
    optionalDataDuplicate: {
      'time_limit': 10,
    },
    freeNumbers: [],
    rules: [],
    rulesArray: [],
    changedNumbers: [],
    changedVendorSlug: null,
    attachedVendor: null,
    counter: 0
  }),
  computed: {
    checkToggleButtonWidth() {
      return this.$vuetify.breakpoint.width < 820
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    freeVendorsNotYou() {
      return this.freeVendors.filter(vendor => !vendor.private)
    },
    vendorOptions() {
      const disabled = this.freeVendors.every(vendor => !vendor.private)
      return [
        { name: 'Me', id: 0, disabled },
        { name: 'Vendor', id: 1 }
      ]
    },
    vendorYou() {
      return this.freeVendors.find(vendor => !!vendor.private)
    },
    getCntNumbers() {
      const cnt = this.changedNumbers.length
      
      return `${cnt} number${cnt - 1 ? 's' : ''}`
    },
  },
  methods: {
    searchNumbers(slug) {
      this.rulesArray = []
      this.changedNumbers = []
      this.freeNumbers = []
      if (slug) {
        const vendorId = this.freeVendors.find(vendor => vendor.slug === slug).id
        this.freeNumbers = this.freeNumbersAll.filter(number => !number['publisher_id'] || number['publisher_id'] === vendorId)
      }
    },
    openVendorDialog() {
      this.dialogVendor = true
    },
    createVendorSuccess(newVendorSlug) {
      this.$emit('reloadCampaignManage')
      this.changedVendorSlug = newVendorSlug
    },
    openByNumberPanel() {
      this.$emit('openByNumberPanel')
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.attachVendor.validate()) {
        this.loading = true

        let formData = Object.assign({}, this.formDataAddVendor)

        if(this.formDataAddVendor['convert_on_id'] === 3) {
          formData = Object.assign(formData, this.optionalData)
        }

        if (this.formDataAddVendor['duplicate_id'] === 3) {
          formData = Object.assign(formData, this.optionalDataDuplicate)
        }

        let formDataFreeNumbers = this.changedNumbers.filter(number => !number['publisher_id']).map(number => number.id)
        let formDataNumbers = this.changedNumbers.map(number => number.id)

        this.counter = !!formDataFreeNumbers.length ? 3 : 2
        
        const { success, payload, message } = await attachVendorToCampaign({
          campaignId: this.campaignSlug,
          formData
        })
        
        if (success) {
          this.attachedVendor = payload
          this.$notify({ type: 'success-bg', text: message })
          this.counter--
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
        
        this.goToAttachNumbersToCampaign(formDataNumbers)
        
        if (!!formDataFreeNumbers.length) {
          this.goToAttachNumbersToVendor(formDataFreeNumbers, formData.publisher)
        }
        
        this.loading = false
      }
    },
    async goToAttachNumbersToCampaign(numbers) {
      const { success, message } = await attachNumberToCampaign({
        campaignId: this.campaignSlug,
        formData: { numbers }
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.counter--
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async goToAttachNumbersToVendor(numbers, vendorSlug) {
      const { success, message } = await attachNumbersToVendor({
        vendorId: vendorSlug,
        formData: { numbers }
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.counter--
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || v === 0 || 'This field is required'
      ]
      this.rulesArray = [
        v => !!v && !!v.length || 'This field is required'
      ]
    },
    closePanel() {
      this.$emit('closePanel')
    },
  },
  watch: {
    currentVendorOptions: {
      immediate: true,
      handler(value) {
        this.formDataAddVendor.publisher = null
        this.rulesArray = []
        this.changedNumbers = []
        this.freeNumbers = []
        
        if (!!value) {
          if (this.changedVendorSlug) {
            this.formDataAddVendor.publisher = this.changedVendorSlug
            this.searchNumbers(this.changedVendorSlug)
            this.changedVendorSlug = null
          }
        } else {
          if (this.vendorYou) {
            this.formDataAddVendor.publisher = this.vendorYou.slug
            this.searchNumbers(this.vendorYou.slug)
          } else {
            this.currentVendorOptions = 1
          }
          
        }
      }
    },
    freeVendors: {
      handler() {
        if (this.changedVendorSlug) {
          this.currentVendorOptions = 1
        }
      }
    },
    freeNumbersAll: {
      handler() {
        if (!!this.formDataAddVendor.publisher) {
          this.searchNumbers(this.formDataAddVendor.publisher)
        }
      }
    },
    counter: {
      handler(counter) {
        if (counter === 0) {
          this.$emit('attachSuccess', {
            numbers: this.changedNumbers,
            vendor: this.attachedVendor
          })
        }
      }
    }
  },
}
</script>
